.l-main {
  padding-left: rem(20);
  padding-right: rem(20);
  padding-bottom: rem(56);

  @include mediaQueryFor(tablet) {
    padding-left: $w-sidebar;
    padding-right: $w-sidebar;
  }

  @include mediaQueryFor(pc) {
    padding-left: #{$w-sidebar * 1.5};
    padding-right: #{$w-sidebar * 1.5};
    padding-bottom: 0;
  }

  @media print {
    padding-bottom: 0;
  }

  &.--with-hero--profile {
    margin-top: calc(100vh - #{rem(168)});
    padding-top: rem(25);

    @include mediaQueryFor(tablet) {
      margin-top: 0;
      padding-top: 0;
    }

    @media print {
      margin-top: rem(220);
    }
  }

  &.--with-hero--business {
    margin-top: calc(100vh - #{rem(140)});
    padding-top: rem(25);

    @include mediaQueryFor(tablet) {
      margin-top: 0;
      padding-top: 0;
    }

    @media print {
      margin-top: rem(220);
    }
  }
}
