.c-curtain {
  @extend .u-absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  &::before,
  &::after {
    @extend .u-content;
    @extend .u-absolute;
    @extend .u-block;
    @extend .u-w-full;
    @extend .u-h-full;
    top: 0;
    left:0;
  }

  &::before {
    background-color: $color-bg--image;
    box-shadow: rem(-32) 0 rem(32) 0 $color-bg--image;
    z-index: 1;
  }

  &::after {
    background-image: linear-gradient(
      245deg,
      $color-bg 20%,
      $color-bg--transparent 20%
    );
    z-index: 2;
  }

  &.--white {
    &::before {
      background-color: $color-bg;
      box-shadow: rem(-32) 0 rem(32) 0 $color-bg;
    }

    &::after {
      display: none;
    }
  }
}

[aria-expanded="true"],
[data-loaded="true"] {
  .c-curtain {
    visibility: hidden;

    &::before,
    &::after {
      visibility: visible;
      transform: translateX(calc(100% + #{rem(64)}));
    }

    &::before {
      transition: transform .6s ease .2s;
    }

    &::after {
      transition: transform .2s ease;
    }
  }
}

@media print {
  .c-curtain {
    display: none;
  }
}
