.l-nav {
  @extend .u-fixed;
  bottom: 0;
  right: 0;
  overflow: visible;
  z-index: 997;

  @include mediaQueryFor(pc) {
    top: 0;
    width: $w-sidebar;
    height: 100vh;
  }

  @media print {
    display: none;
  }

  &::after {
    @extend .u-content;
    @extend .u-block;
    @extend .u-fixed;
    @extend .u-bg-white;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(180);
    box-shadow: 0 0 rem(32) rem(32) $color-bg;
    visibility: hidden;
    transition: visibility 0;

    @include mediaQueryFor(tablet) {
      width: calc(100% - #{$w-sidebar * 1.5});
    }
  }

  &_sidebar {
    @include mediaQueryFor(pc) {
      width: 100%;
      height: 100%;
      padding: rem(16);
      border-left: 1px solid $color-border;
      background-color: $color-bg;
    }
  }

  &_button {
    @extend .u-block;
    margin-bottom: calc(#{rem(31)} + env(safe-area-inset-bottom));
    margin-right: rem(20);
    width: rem(52);
    height: rem(52);
    color: $color-title;
    background-color: $color-bg;
    border-radius: 50%;
    box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, .24);
    z-index: 99;

    &:focus {
      box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, .24),
        0 0 0 rem(2) $color-bg--image;
    }

    @include mediaQueryFor(pc) {
      width: rem(48);
      height: rem(72);
      margin: 0;
      text-align: center;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;

      &:focus {
        box-shadow: 0 0 0 rem(2) $color-bg--image;
      }
    }

    &_inner {
      @extend .u-w-full;
      @extend .u-h-full;
      padding: .875rem;
      border-radius: 50%;

      @include mediaQueryFor(pc) {
        padding: rem(4);
        border-radius: 0;
      }
    }

    &_hamburger {
      @extend .u-relative;
      @extend .u-flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 3px 2px;
      margin-left: auto;
      margin-right: auto;

      @include mediaQueryFor(pc) {
        width: rem(36);
        height: rem(36);
      }

      &::before,
      &::after {
        @extend .u-content;
      }

      &::before,
      & span,
      &::after {
        @extend .u-block;
        @extend .u-w-full;
        height: 10%;
        margin: 0 auto;
        background-color: currentColor;
        transform-origin: center;
        transition: .15s;
      }
    }

    &_label {
      display: none;

      @include mediaQueryFor(pc) {
        display: block;
        width: rem(48);
        margin-top: rem(-4);
        margin-left: rem(-4);
        font-family: var(--font-title--en);
        font-size: rem(12);
        line-height: 2.75;
        letter-spacing: rem(1.28);
        animation: nav-fade-out-in .6s ease-in-out;
      }
    }
  }

  &_contents {
    @extend .u-absolute;
    @extend .u-bg-white;
    @extend .u-transition;
    top: calc(#{rem(82)} + env(safe-area-inset-bottom) - 100vh);
    right: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    filter: blur(24px);
    backdrop-filter: blur(24px);
    overflow-y: scroll;
    z-index: -1;
    -ms-overflow-style: none;
    transition: visibility .5s ease, opacity .5s ease;

    @include mediaQueryFor(pc) {
      top: 0;
      right: auto;
      left: calc(#{$w-sidebar} - 100vw);
      width: calc(100vw - #{$w-sidebar});
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &_links {
    @extend .u-transition;
    margin: rem(218) rem(48) rem(80) rem(48);

    @include mediaQueryFor(tablet) {
      width: rem(444);
      margin: rem(216) auto rem(120) auto;
      padding-left: rem(132);
    }

    a {
      @extend .u-inline-block;
    }
  }

  &_link {
    margin-top: rem(26);

    & + .l-nav_link {
      margin-top: rem(12);

      @include mediaQueryFor(tablet) {
        margin-top: rem(28);
      }
    }

    &:nth-child(1) {
      .c-curtain::before {
        transition-delay: .1s;
      }
    }

    &:nth-child(2) {
      .c-curtain::before {
        transition-delay: .2s;
      }
    }

    &:nth-child(3) {
      .c-curtain::before {
        transition-delay: .3s;
      }
    }

    &:nth-child(4) {
      .c-curtain::before {
        transition-delay: .4s;
      }
    }

    &:nth-child(5) {
      .c-curtain::before {
        transition-delay: .5s;
      }
    }

    &_title {
      @extend .u-headline-sp-title-pc;
      @extend .u-transition;
      height: rem(62);
      padding: rem(16) rem(54) rem(16) rem(16);
      cursor: pointer;

      @include mediaQueryFor(tablet) {
        height: rem(74);
        padding-right: rem(70);
      }
    }
  }

  .c-accordion input:checked + label + .c-accordion_menu {
    visibility: hidden;
  }

  &[aria-expanded="true"] {
    &::after {
      visibility: visible;
      transition: visibility .5s ease 1s;
    }

    & .l-nav_contents {
      visibility: visible;
      opacity: 1;
      filter: blur(0);
      transition: visibility .5s ease, filter .5s ease, opacity 1s ease;
    }

    & .l-nav_button {
      &_label {
        animation: nav-fade-in-out .6s ease-in-out;
      }

      &_hamburger {
        &::before,
        &::after {
          width: 102.05%;
          transition: .15s .15s;
          transform-origin: center center;

          @include mediaQueryFor(pc) {
            width: 45px;
          }
        }

        &::before {
          transform: translateY(8px) rotate(45deg);

          @include mediaQueryFor(pc) {
            transform: translate(-6px, 14px) rotate(45deg);
          }
        }

        & span {
          width: 0;
          transition: .15s;
        }

        &::after {
          transform: translateY(-8px) rotate(-45deg);

          @include mediaQueryFor(pc) {
            transform: translate(-6px, -14px) rotate(-45deg);
          }
        }
      }
    }

    .c-accordion input:checked + label + .c-accordion_menu {
      visibility: visible;
    }
  }
}

@keyframes nav-fade-out-in {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes nav-fade-in-out {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
