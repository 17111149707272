.p-hero {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: rem(400);
  padding: rem(28) rem(30) 0 rem(30);
  z-index: -1;

  @include mediaQueryFor(tablet) {
    position: relative;
    width: calc(100% - #{$w-sidebar});
    padding: 0;
  }

  @media print {
    position: absolute;
    top: rem(20);
    right: rem(20);
    width: rem(560);
    min-height: rem(240);
    padding: 0;
  }

  &_bg {
    @extend .u-bg-image;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform .1s ease-out;

    @include mediaQueryFor(tablet) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.--business {
    height: calc(100vh - #{rem(140)});

    @include mediaQueryFor(tablet) {
      height: rem(600);
    }

    @include mediaQueryFor(pc) {
      padding: 0 #{$w-sidebar * .5} 0 #{$w-sidebar * 1.5};
    }

    @media print {
      height: rem(280);
    }

    & .p-hero_content {
      @extend .u-absolute;
      @extend .u-w-full;
      @extend .u-h-full;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .p-hero_title {
      text-align: center;

      svg,
      span {
        @extend .u-block;
      }

      svg {
        width: rem(286);
        height: rem(27);
        margin-bottom: rem(14);
        fill: currentColor;

        @include mediaQueryFor(tablet) {
          width: rem(350);
          height: rem(30);
          margin-bottom: rem(19);
          margin-right: rem(12);
        }
      }

      span {
        @extend .u-block;

        @include mediaQueryFor(tablet) {
          margin-right: rem(34);
        }
      }
    }
  }

  &.--profile {
    height: calc(100vh - #{rem(168)});

    @include mediaQueryFor(tablet) {
      max-width: width(14);
      height: rem(600);
      margin: 0 auto;
    }

    @media print {
      height: rem(280);
    }

    & .p-hero_bg {
      // SP と PC 共通のシャドウのため
      box-shadow: rem(10) rem(80) rem(60) 0 rgba(0, 0, 0, .08);

      @include mediaQueryFor(tablet) {
        max-width: width(10);
        left: auto;
        right: - $w-sidebar;
      }

      @include mediaQueryFor(pc) {
        right: #{$w-sidebar * -.5};
      }
    }
  }

  &--policy {
    height: auto;
  }
}
