// Position
.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

// Display
.u-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}

.u-flex {
  display: flex;
}

// Width
.u-w-full {
  width: 100%;
}

.u-max-w-12--auto {
  @extend .u-mh-auto;
  @include mediaQueryFor(tablet) {
    max-width: width(12);
  }
}

.u-max-w-10--auto {
  @extend .u-mh-auto;
  @include mediaQueryFor(tablet) {
    max-width: width(10);
  }
}

.u-max-w-8 {
  @include mediaQueryFor(tablet) {
    max-width: width(8);
  }
}

.u-max-w-8--auto {
  @extend .u-mh-auto;
  @extend .u-max-w-8;
}

// Height
.u-h-full {
  height: 100%;
}

// Margin
.u-mh-auto {
  margin-left: auto;
  margin-right: auto;
}

// Font
.u-title {
  font-family: var(--font-title);
}

.u-title--en {
  font-family: var(--font-title--en);
}

.u-title,
.u-title--en {
  color: $color-title;
  font-weight: var(--weight-title);
}

.u-text-small-only-sp {
  font-size: var(--text-small);
  line-height: var(--line-small);
  letter-spacing: var(--letter-small);

  @include mediaQueryFor(pc) {
    font-size: var(--text-default);
    line-height: var(--line-default);
    letter-spacing: var(--letter-default);
  }
}

.u-text-small-only-pc {
  @include mediaQueryFor(pc) {
    font-size: var(--text-small);
    line-height: var(--line-small);
    letter-spacing: var(--letter-small);
  }
}

.u-headline-sp-title-pc {
  @extend .u-title--en;
  font-size: var(--text-headline--en);
  line-height: var(--line-headline--en);
  letter-spacing: var(--letter-headline--en);

  @include mediaQueryFor(pc) {
    font-size: var(--text-title--en);
    line-height: var(--line-title--en);
    letter-spacing: var(--letter-title--en);
  }
}

// Color
.u-color-white {
  color: $color-gray-00;
}

// Background
.u-bg-white {
  background-color: $color-bg;
}

.u-bg-image {
  background-color: $color-bg--image;
}

// Border
.u-border-bottom {
  border-bottom: 1px solid $color-border;
}

// Shadow
.u-shadow {
  box-shadow: rem(8) rem(20) rem(60) 0 rgba(0, 0, 0, .2);

  @include mediaQueryFor(tablet) {
    box-shadow: rem(10) rem(80) rem(60) 0 rgba(0, 0, 0, .08);
  }
}

.u-drop-shadow {
  filter: drop-shadow(0 0 #{rem(28)} rgba(0, 0, 0, .8));
}

.u-drop-shadow--sm {
  filter: drop-shadow(0 0 #{rem(20)} rgba(0, 0, 0, .8));
}

// Transition
.u-transition {
  transition: .3s ease;
}

// Pseudo
.u-content {
  content: "";
}

// Tranform animation
.u-transformer {
  transform-origin: center bottom;
  transform: scale(.9, .96);
  overflow: hidden;

  &_fixed {
    @extend .u-w-full;
    @extend .u-h-full;
    transform-origin: center bottom;
    transform: scale(1.1111, 1.0417);
  }
}

[data-loaded="true"] .u-transformer {
  transform: scale(1);
  transition: transform .5s ease;

  &_fixed {
    transform: scale(1);
    transition: transform .5s ease;
  }
}

@media print {
  .u-transformer {
    transform: scale(1);

    &_fixed {
      transform: scale(1);
    }
  }
}

.u-fadein {
  opacity: 0;
}

.u-fadein[data-loaded="true"] {
  opacity: 1;
  transition: opacity .4s ease;
}

@media print {
  .u-fadein {
    opacity: 1;
  }
}

// Print

@media print {
  .u-print-break {
    page-break-after: always;
  }
}