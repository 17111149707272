.c-title-animation {
  width: rem(290);

  @include mediaQueryFor(pc) {
    width: rem(467);
  }

  &.--whoweare {
    transform: translateX(#{rem(20)});
    height: rem(118);

    @include mediaQueryFor(pc) {
      height: rem(190);
      transform: translateX(#{rem(16)});
    }
  }

  &.--outline {
    height: rem(50.9263);

    @include mediaQueryFor(pc) {
      height: rem(82);
    }
  }

  &.--whatwedo {
    height: rem(121.1053);

    @include mediaQueryFor(pc) {
      height: rem(195);
    }
  }

  &.--policy {
    width: rem(245.2893);
    height: rem(140);

    @include mediaQueryFor(pc) {
      display: none;
    }

    &--pc {
      display: none;

      @include mediaQueryFor(pc) {
        display: block;
        width: rem(793);
        height: rem(109);
      }
    }
  }

  &.--contact,
  &.--confirm,
  &.--thank {
    height: rem(67.6874);

    @include mediaQueryFor(pc) {
      height: rem(109);
    }
  }

  &.--thank {
    width: 100%;
    max-width: rem(357.6874);

    @include mediaQueryFor(pc) {
      width: rem(576);
    }
  }

  &.--confirm,
  &.--thank {
    @extend .u-absolute;
    top: 0;
    left: 0;
  }

  &_line {
    fill: $color-title--transparent;
    stroke: $color-title;
    stroke-dasharray: 550 550;

    &.--short {
      stroke-dasharray: 300 300;
    }

    &.--shorter {
      stroke-dasharray: 200 200;
    }
  }

  &:not([data-loaded="true"]) {
    .c-title-animation_line {
      fill: $color-title--transparent;
      stroke: $color-title;
      stroke-dashoffset: 550;
      transition: none;

      &.--short {
        stroke-dashoffset: -300;
      }

      &.--shorter {
        stroke-dashoffset: -200;
      }
    }
  }

  &[data-loaded="true"] {
    .c-title-animation_line {
      stroke: $color-title--transparent;
      stroke-dashoffset: -550;
      transition: stroke-dashoffset 1s ease, stroke .1s ease .9s;

      &.--short {
        stroke-dashoffset: 300;
      }

      &.--shorter {
        stroke-dashoffset: 200;
      }

      &.--late {
        transition: stroke-dashoffset 1s ease .2s, stroke .1s ease 1.1s;
      }

      &.--fill {
        fill: $color-title;
        transition: stroke-dashoffset 1s ease .3s, stroke .1s ease 1.2s, fill 1s ease .5s;
      }
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
  }

  @media print {
    .c-title-animation_line {
      stroke: $color-title--transparent;

      &.--fill {
        fill: $color-title;
      }
    }
  }
}
