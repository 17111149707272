.p-splash {
  @extend .u-fixed;
  @extend .u-flex;
  @extend .u-w-full;
  @extend .u-h-full;
  @extend .u-bg-white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;

  &_tagline {
    &_animation {
      @extend .u-relative;
      width: rem(250);
      height: rem(24);
      margin-left: rem(16);

      @include mediaQueryFor(tablet) {
        width: rem(500);
        height: rem(48);
      }

      svg {
        height: 100%;
        fill: $color-title;
      }

      span {
        @extend .u-absolute;
        @extend .u-flex;
        @extend .u-w-full;
        @extend .u-h-full;
        justify-content: flex-end;
        top: 0;
        left: rem(-2);

        &::before {
          width: 1px;
          background-color: $color-text;
          opacity: 0;
        }

        &::after {
          @extend .u-bg-white;
          flex-grow: 1;
        }

        &::before,
        &::after {
          @extend .u-inline-block;
          @extend .u-content;
          height: rem(30);

          @include mediaQueryFor(tablet) {
            height: rem(54);
          }
        }
      }

      &:first-child {
        margin-left: rem(18);
        margin-bottom: rem(20);

        @include mediaQueryFor(tablet) {
          margin-left: rem(20);
          margin-bottom: rem(40);
        }
      }
    }
  }

  &_logo {
    @extend .u-absolute;
    @extend .u-flex;
    @extend .u-w-full;
    @extend .u-h-full;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &_mark,
    &_ja {
      width: rem(170);

      @include mediaQueryFor(tablet) {
        width: rem(340);
      }
    }

    &_mark {
      @extend .u-relative;
      height: rem(170);
      margin-bottom: rem(20);

      @include mediaQueryFor(tablet) {
        height: rem(340);
        margin-bottom: rem(40);
      }

      &::after {
        @extend .u-absolute;
        @extend .u-block;
        @extend .u-content;
        @extend .u-w-full;
        @extend .u-h-full;
        top: 0;
        left: 0;
        background-image: linear-gradient($color-bg 80%, $color-bg--transparent);
        transform-origin: right bottom;
        transform: scale(1.8) rotate(135deg) translate(50%, 100%);
      }
    }

    &_ja {
      @extend .u-relative;

      svg {
        fill: $color-title--transparent;
        stroke: $color-title;
        stroke-width: .1;

        &:first-child {
          height: rem(24);

          @include mediaQueryFor(tablet) {
            height: rem(48);
          }
        }

        &:last-child {
          @extend .u-absolute;
          top: rem(-32);
          left: rem(-24);
          width: rem(192);
          height: rem(64);

          @include mediaQueryFor(tablet) {
            top: rem(-65);
            left: rem(-47);
            width: rem(384);
            height: rem(128);
          }
        }
      }
    }
  }

  &_stroke {
    stroke-dasharray: 15 15;
    stroke-dashoffset: -15;

    &.--long {
      stroke-dasharray: 45 45;
      stroke-dashoffset: 45;
    }
  }

  &[data-loaded="true"] {
    opacity: 0;
    filter: blur(24px);
    transition: opacity 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 9.75s, filter 2.5s ease 9.25s;

    .p-splash_tagline_animation {
      opacity: 0;
      transition: opacity 1s ease 5.5s;

      span {
        animation: splash-tiping-sp 1.8s ease 1s forwards ;

        @include mediaQueryFor(tablet) {
          animation: splash-tiping-pc 1.8s ease 1s forwards ;
        }

        &::before {
          animation: splash-flash .5s ease infinite;
        }
      }

      &:last-child{
        span {
          animation-delay: 3s;
          animation-timing-function: cubic-bezier(.43,.01,0,.99);

          &::before {
            animation-delay: 2.5s;
          }
        }
      }
    }

    .p-splash_logo {
      opacity: 1;
      transition: opacity .1s ease 6.5s;

      &_mark {
        &::after {
          transform: scale(1.8) rotate(135deg) translate(50%, 0%);
          transition: transform 1.25s ease 6.5s;
        }
      }

      &_ja {
        svg:first-child {
          fill: $color-gray-70;
          stroke: $color-title;
          transition: fill 1s ease 7.45s, stroke-dashoffset 1s ease 7.2s;
        }
      }
    }

    .p-splash_stroke {
      stroke-dashoffset: 15;
      transition: stroke-dashoffset 1s ease 7.1s;

      &.--long {
        stroke-dashoffset: -45;
      }
    }
  }

  &[aria-hidden="true"] {
    display: none;
  }
}

@keyframes splash-flash {
  0% { opacity: 0 }
  49% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 1 }
}

@keyframes splash-tiping-sp {
  0% {
    transform: translateX(#{rem(25)});
  }
  9% {
    transform: translateX(#{rem(25)});
  }
  10% {
    transform: translateX(#{rem(55)});
  }
  21% {
    transform: translateX(#{rem(55)});
  }
  22% {
    transform: translateX(#{rem(85)});
  }
  31% {
    transform: translateX(#{rem(85)});
  }
  32% {
    transform: translateX(#{rem(115)});
  }
  43% {
    transform: translateX(#{rem(115)});
  }
  44% {
    transform: translateX(#{rem(145)});
  }
  53% {
    transform: translateX(#{rem(145)});
  }
  54% {
    transform: translateX(#{rem(175)});
  }
  65% {
    transform: translateX(#{rem(175)});
  }
  66% {
    transform: translateX(#{rem(205)});
  }
  75% {
    transform: translateX(#{rem(205)});
  }
  76% {
    transform: translateX(#{rem(235)});
  }
  84% {
    transform: translateX(#{rem(235)});
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(#{rem(235)});
    opacity: 0;
  }
}

@keyframes splash-tiping-pc {
  0% {
    transform: translateX(#{rem(50)});
  }
  9% {
    transform: translateX(#{rem(50)});
  }
  10% {
    transform: translateX(#{rem(110)});
  }
  21% {
    transform: translateX(#{rem(110)});
  }
  22% {
    transform: translateX(#{rem(170)});
  }
  31% {
    transform: translateX(#{rem(170)});
  }
  32% {
    transform: translateX(#{rem(230)});
  }
  43% {
    transform: translateX(#{rem(230)});
  }
  44% {
    transform: translateX(#{rem(290)});
  }
  53% {
    transform: translateX(#{rem(290)});
  }
  54% {
    transform: translateX(#{rem(350)});
  }
  65% {
    transform: translateX(#{rem(350)});
  }
  66% {
    transform: translateX(#{rem(410)});
  }
  75% {
    transform: translateX(#{rem(410)});
  }
  76% {
    transform: translateX(#{rem(470)});
  }
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(#{rem(470)});
    opacity: 0;
  }
}
