.c-accordion {
  label {
    @extend .u-relative;
    @extend .u-block;

    &::before,
    &::after {
      content: "";
      @extend .u-absolute;
      @extend .u-block;
      @extend .u-transition;
      top: rem(30);
      left: rem(176);
      width: rem(20);
      height: 1px;
      background-color: $color-title;

      @include mediaQueryFor(pc) {
        top: rem(35);
        left: auto;
        right: rem(24);
      }
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &_menu {
    @extend .u-transition;
    height: 0;
    padding: 0 rem(16);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1rem);

    @include mediaQueryFor(pc) {
      padding: 0 rem(40);
    }

    li {
      + li {
        @include mediaQueryFor(pc) {
          margin-top: rem(4);
        }
      }

      a {
        @extend .u-text-small-only-sp;
        padding: rem(4) rem(16);

        @include mediaQueryFor(pc) {
          padding: rem(8) rem(16);
        }
      }
    }
  }

  input {
    display: none;

    &:checked {
      + label {
        &::after {
          transform: rotate(90deg) scaleX(0);
        }

        + .c-accordion_menu {
          height: rem(78);
          padding: rem(10) rem(14);
          transform: translateY(0);
          opacity: 1;
          visibility: visible;

          @include mediaQueryFor(pc) {
            height: rem(142);
            padding: rem(26) rem(40);
          }
        }
      }
    }
  }
}
