.p-timeline {
  position: relative;
  width: 100%;
  overflow: visible;

  @include mediaQueryFor(tablet) {
    display: flex;
    flex-direction: row-reverse;
    max-width: rem(1068);
    margin: rem(95) auto 0 auto;
    transform: translateX(1.5rem);

    + .p-timeline {
      margin-top: rem(81);
    }
  }

  &:last-child {
    margin-bottom: rem(176);

    @include mediaQueryFor(tablet) {
      margin-bottom: rem(112);
    }
  }

  &_headline {
    @extend .u-title--en;
    margin: rem(40) 0 rem(8) 0;
    text-align: center;
    font-size: var(--text-headline--en);
    line-height: var(--line-headline--en);
    letter-spacing: var(--letter-headline--en);

    @include mediaQueryFor(tablet) {
      writing-mode: vertical-rl;
      display: flex;
      text-align: left;
      width: rem(32);
      margin: 0;
      font-size: var(--text-headline--sm);
      line-height: var(--line-headline--sm);
      letter-spacing: var(--letter-headline--sm);

      &::after {
        flex-grow: 1;
        content: "";
        display: block;
        width: 1px;
        margin: rem(12) rem(14) 0 rem(18);
        background-color: $color-gray-20;
      }
    }
  }

  &[data-top] .p-timeline_headline {
    margin-top: rem(60);

    @include mediaQueryFor(tablet) {
      margin-top: rem(40);
    }
  }

  &_card {
    width: calc(100% + #{rem(20)});
    margin-left: rem(-10);
    padding: rem(30) rem(30) rem(40) rem(30);
    @extend .u-bg-white;

    @include mediaQueryFor(tablet) {
      width: calc(100% - var(--text-headline) * var(--line-headline) - 1rem);
      margin-left: 0;
      margin-right: 1rem;
      padding: rem(40);
    }

    @include mediaQueryFor(pc) {
      padding: rem(72) rem(80);
    }

    & p {
      @extend .u-text-small-only-sp;
      margin-top: rem(15);
    }
  }

  &_summary {
    @extend .u-title;
    font-size: var(--text-headline);
    line-height: var(--line-headline);
    letter-spacing: var(--letter-headline);

    @include mediaQueryFor(tablet) {
      font-size: var(--text-headline--sm);
      line-height: var(--line-headline--sm);
      letter-spacing: var(--letter-headline--sm);
    }
  }
}
