.c-text-incoming {
  color: transparent;
  background-image: linear-gradient(90deg, #{$color-text} 90%, #{$color-text--transparent} calc(90% + 64px));
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: right -100% center;

  &.--title {
    background-image: linear-gradient(90deg, #{$color-title} 90%, #{$color-text--transparent} calc(90% + 64px));
  }
}

[data-loaded="true"] .c-text-incoming {
  background-position: right calc(20% - 64px) center;
  transition: background-position 1s ease;
}

@media print {
  .c-text-incoming {
    color: currentColor;
    background: none;

    &.--title {
      background: none;
    }
  }
}
