.p-profile {
  @include mediaQueryFor(tablet) {
    margin-top: rem(-296);
  }

  @media print {
    margin-top: rem(0);
  }

  &_section {
    @include mediaQueryFor(tablet) {
      margin-top: rem(80);
    }

    p {
      margin-top: rem(38);

      &.c-text-incoming {
        transition-delay: .3s;
      }
    }

    &[data-naoko] {
      @include mediaQueryFor(tablet) {
        & .p-profile_name {
          margin-bottom: rem(260);
        }
      }

      @include mediaQueryFor(pc) {
        & .p-profile_name {
          margin-bottom: rem(302);
        }
      }
    }

    @include mediaQueryFor(tablet) {
      & p + p {
        margin-top: calc(var(--line-default) * var(--text-default));
      }

      &_text {
        max-width: #{$w-column * 8 + $w-gutter * 9};
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &_name {
    max-width: rem(300);
    line-height: 1;

    @include mediaQueryFor(tablet) {
      margin-bottom: rem(220);
    }

    &_en {
      @extend .c-headline-sp-title-pc;
    }

    &.c-text-incoming {
      transition-delay: .2s;
    }
  }

  &_headline {
    @extend .u-headline-sp-title-pc;
    margin: 2.5rem 0;

    @include mediaQueryFor(tablet) {
      max-width: 66.875rem;
      margin-left: auto;
    }
  }

  &_outline {
    @include mediaQueryFor(tablet) {
      dl {
        dt {
          width: rem(220);
        }

        dd {
          width: calc(100% - #{rem(220)});
        }
      }
    }
  }

  &_button {
    margin: 2.5rem auto;

    @include mediaQueryFor(tablet) {
      margin: rem(58) auto rem(60) auto;
    }

    @include mediaQueryFor(pc) {
      margin: rem(58) auto rem(188) auto;
    }
  }

  &_homelink {
    margin: rem(92) 0 rem(9) 0;
    text-align: center;

    @include mediaQueryFor(tablet) {
      display: none;
    }

    @media print {
      display: none;
    }
  }
}
