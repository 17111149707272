@font-face {
  font-family: "Optima nova";
  src: url("/fonts/optima.woff2") format("woff2"), url("/fonts/optima.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium"), local("YuGothic-Medium");
  font-display: swap;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold"), local("YuGothic-Bold");
  font-weight: bold;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:focus {
  outline: none;
  box-shadow: 0 0 0 rem(2) $color-bg--image;
}

::selection {
  background-color: $color-bg--image;
}

html,
body {
  width: 100%;
}

body {
  color: $color-black-100;
  font-family: $font-helvetica, $font-hiragino, $font-yu-gothic, sans-serif;
  // 1rem = 16px を基準とするため、root ではなく body でサイズ指定
  font-size: var(--text-default);
  font-weight: var(--weight-default);
  line-height: var(--line-default);
  letter-spacing: var(--letter-default);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include mediaQueryFor(pc) {
    color: $color-text;
    font-family: $font-yu-gothic, sans-serif;
  }

  @media print {
    color: #000;
    font-family: $font-yu-gothic, sans-serif;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $color-title;
  font-weight: var(--weight-title);
}

ul {
  list-style: none;
}

a, button {
  text-decoration: none;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  cursor: pointer;
}

a, button, span {
  color: inherit;
}

button {
  border: none;
}

img,
svg {
  width: 100%;
  height: 100%;
}

svg:not([fill]) {
  fill: inherit;
}

svg:not([stroke]) {
  stroke: inherit;
}
