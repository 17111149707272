.c-text-default {
  font-size: var(--text-default);
  font-weight: var(--weight-default);
  line-height: var(--line-default);
  letter-spacing: var(--letter-default);
}

.c-text-small {
  font-size: var(--text-small);
  letter-spacing: var(--letter-small);
  line-height: var(--line-small);
}
