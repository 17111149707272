.p-contact {
  &_header {
    padding-top: rem(144);

    @include mediaQueryFor(tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      padding-top: rem(120);
    }

    @include mediaQueryFor(pc) {
      padding-top: rem(200);
    }

    p {
      margin-top: rem(48);

      &[aria-hidden="true"] {
        display: none;
      }
    }
  }

  &_thank {
    display: none;

    &[data-sended="true"] {
      @extend .u-block;
      text-align: center;
      margin: rem(76) 0 rem(20) 0;

      @include mediaQueryFor(pc) {
        margin: rem(108) 0 rem(96) 0;
      }

      & .p-contact_thank_text {
        margin-top: rem(34);
        margin-bottom: rem(46);

        @include mediaQueryFor(pc) {
          margin-top: rem(42);
          margin-bottom: rem(24);
        }
      }

      & .p-contact_thank_address {
        margin-bottom: rem(40);

        @include mediaQueryFor(pc) {
          margin-bottom: rem(70);
        }

        p {
          margin-top: rem(14);

          @include mediaQueryFor(pc) {
            margin-top: rem(12);
          }
        }
      }

      & .p-contact_thank_button {
        margin: 0 auto;
      }
    }
  }
}
