.p-service {
  @extend .u-relative;

  + .p-service {
    margin: rem(88) 0;

    @include mediaQueryFor(pc) {
      margin: 0;
    }
  }

  @include mediaQueryFor(pc) {
    padding-top: rem(112);

    &:nth-last-child(2) {
      margin-bottom: rem(120);
    }

    &_wrap {
      display: flex;
      justify-content: space-between;
    }
  }

  &_number {
    @extend .u-relative;

    span {
      opacity: 0;
    }

    .c-title-animation {
      @extend .u-absolute;
      @extend .u-block;
      top: rem(6);
      left: 0;
      width: rem(77.4);
      height: rem(65.4);

      @include mediaQueryFor(tablet) {
        top: 0;
        width: rem(129);
        height: rem(109);
      }

      &[data-loaded="true"] {
        .c-title-animation_line {
          transition: stroke-dashoffset 1s ease .6s, stroke .1s ease 1.5s;
        }
      }
    }
  }

  &_name {
    color: $color-title;
    margin-bottom: rem(40);

    @include mediaQueryFor(pc) {
      display: inline-block;
      min-width: rem(192);
      max-width: rem(324);
      margin-right: rem(32);
    }

    span {
      display: block;

      &:first-child {
        margin-right: rem(12);
      }

      &:nth-child(2) {

        @include mediaQueryFor(pc) {
          margin-top: rem(6);
        }
      }
    }

    small {
      @extend .u-block;
      font-size: var(--text-headline--sm);
      line-height: var(--line-headline--sm);
      letter-spacing: var(--letter-headline--sm);

      @include mediaQueryFor(pc) {
        margin-top: rem(5);
      }
    }

    .c-curtain {
      &::before {
        transition-delay: .6s;
      }
    }
  }

  &_contents {
    width: 100%;

    @include mediaQueryFor(pc) {
      display: inline-block;
      max-width: width(8);
      margin-top: rem(125);
      margin-right: 0;
      padding: 0;
    }

    b {
      display: block;
      color: $color-title;
      font-family: var(--font-title);
      font-weight: normal;
      font-size: var(--text-headline);
      line-height: var(--line-headline);
      letter-spacing: var(--letter-headline);

      @include mediaQueryFor(tablet) {
        margin-bottom: rem(43);
        font-size: var(--text-headline--sm);
        line-height: var(--line-headline--sm);
        letter-spacing: var(--letter-headline--sm);
      }
    }

    p {
      margin: rem(20) 0 rem(30) 0;

      @include mediaQueryFor(tablet) {
        margin: rem(34) 0 rem(40) 0;
      }
    }

    ol {
      width: calc(100% + #{rem(40)});
      margin-left: rem(-20);
      padding: rem(34) rem(20) rem(40) rem(57);
      list-style: none;
      counter-reset: taskNum;

      @include mediaQueryFor(tablet) {
        width: 100%;
        margin-left: 0;
        padding: rem(40) rem(20) rem(40) rem(64);
      }

      @include mediaQueryFor(pc) {
        padding-left: rem(84);
      }

      li {
        position: relative;

        & + li {
          margin-top: calc(var(--text-default) * var(--line-default));

          @media print {
            margin-top: rem(16);
          }
        }

        &::before {
          @extend .c-headline--en;
          content: counter(taskNum)".";
          position: absolute;
          top: rem(4);
          left: rem(-37);
          display: inline-block;
          counter-increment: taskNum;
          font-size: var(--text-default);

          @include mediaQueryFor(tablet) {
            top: 0;
            left: rem(-32);
            font-size: var(--text-headline--en);
          }

          @include mediaQueryFor(pc) {
            left: rem(-45);
          }
        }
      }
    }

    .c-curtain {
      &::before {
        transition-delay: 1s;
      }
    }
  }

  &[data-loaded="true"] {
    .p-service_number {
      span {
        opacity: 1;
        transition: opacity .5s ease .8s;
      }
    }

    .c-curtain {
      &::before {
        transition: transform .6s ease .6s;
      }
    }
  }

  &:not([data-loaded="true"]) {
    .c-curtain {
      &::before {
        transform: translateX(0);
      }
    }
  }

  @media print {
    .p-service_number {
      span {
        opacity: 1;
      }
    }
  }

  &--naoko {
    & .p-service_contents {
      ol {
        background-color: $color-key-red-bg;
      }

      li::before {
        color: $color-key-red-light;
      }
    }
  }

  &--shinichi {
    & .p-service_contents {
      ol {
        background-color: $color-key-navy-bg;

        li::before {
          color: $color-key-navy-light;
        }
      }
    }
  }
}
