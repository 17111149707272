.p-business {
  @extend.u-block;

  @include mediaQueryFor(tablet) {
    position: relative;
    padding: 0 rem(80);
  }

  &_header {
    width: 100%;
    height: 58.6957vw;

    @include mediaQueryFor(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      height: rem(400);
    }

    @include mediaQueryFor(pc) {
      left: #{$w-column * -.5};
      max-width: calc(50vw + #{width(4)} + #{$w-gutter * .5});
      height: rem(600);
    }

    @media print {
      height: rem(200);
    }

    &_tagline {
      @extend .u-w-full;
      height: 58.6957vw;
      padding-top: rem(90);
      text-align: center;
      background-position: center;
      background-size: cover;

      @include mediaQueryFor(tablet) {
        height: rem(400);
        padding-top: rem(160);
      }

      @include mediaQueryFor(pc) {
        height: rem(600);
        margin-right: rem(16);
        padding-top: rem(246);
      }

      @media print {
        height: rem(200);
        padding-top: rem(72);
      }

      svg,
      span {
        @extend .u-block;
        display: block;
        text-align: center;
      }

      & svg {
        height: rem(22);
        margin: 0 auto rem(8) auto;
        fill: $color-bg;

        @include mediaQueryFor(tablet) {
          width: rem(325);
          height: rem(32);
          margin-bottom: rem(18);
        }
      }

      & span {
        margin-right: rem(8);
        color: currentColor;
        font-family: var(--font-title--en);
        font-weight: var(--weight-title);

        @include mediaQueryFor(tablet) {
          margin-right: rem(16);
          font-size: var(--text-headline--en);
          line-height: var(--line-headline--en);
          letter-spacing: var(--letter-headline--en);
        }
      }
    }
  }

  &_contents {
    margin-top: rem(-36);
    padding: 0 rem(20);

    @include mediaQueryFor(tablet) {
      margin: 0;
      padding: rem(340) 0 0 0;
    }

    @include mediaQueryFor(pc) {
      padding: rem(440) 0 0 0;
    }

    @media print {
      margin-top: rem(-16);
    }

    &_wrap {
      @extend .u-relative;
      width: 0;
      overflow: hidden;

      &::after {
        @extend .u-content;
        @extend .u-absolute;
        @extend .u-block;
        @extend .u-h-full;
        top: 0;
        right: 0;
        width: rem(32);
        background-image: linear-gradient(90deg, $color-bg--transparent, $color-bg 90%);

        @include mediaQueryFor(tablet) {
          width: rem(48);
        }
      }
    }

    &_inner {
      width: rem(334);
      padding: rem(20);

      @include mediaQueryFor(tablet) {
        width: rem(608);
        margin-top: 0;
        padding: rem(60) rem(60) rem(52.5) rem(60);
      }

      @include mediaQueryFor(pc) {
        width: width(7);
        height: rem(624);
      }

      @media print {
        width: width(7);
      }

      & p {
        @extend .u-text-small-only-sp;

        + p {
          margin-top: calc(var(--line-small) * var(--text-small));

          @include mediaQueryFor(tablet) {
            margin-top: calc(var(--line-default) * var(--text-default));
          }

          @media print {
            margin-top: rem(16);
          }
        }

        &:nth-last-child(2) {
          margin-bottom: rem(27.5);
        }
      }
    }
  }

  &.--shinichi {
    margin-top: rem(-40);

    @include mediaQueryFor(tablet) {
      position: relative;
    }

    @include mediaQueryFor(pc) {
      margin-top: rem(-60);
    }

    .p-business_header_tagline {
      background-image: url("/images/top-business-shinichi-pc.jpg");
    }

    .p-business_contents_wrap {
      margin-left: calc(100% - #{rem(334)});

      @include mediaQueryFor(tablet) {
        margin-left: calc(100% - #{rem(608)});
      }

      @include mediaQueryFor(pc) {
        margin-left: calc(100% - #{width(7)});
      }

      @media print {
        margin-left: calc(100% - #{width(7)});
      }
    }
  }

  &.--naoko {
    margin-top: rem(52);

    @include mediaQueryFor(tablet) {
      margin-top: rem(80);
    }

    @include mediaQueryFor(pc) {
      margin-top: rem(120);
    }

    @media print {
      margin-top: rem(32);
    }

    .p-business_header {
      left: auto;
      right: 0;

      &_tagline {
        background-image: url("/images/top-business-naoko-pc.jpg");
      }
    }
  }

  &[data-loaded="true"] {
    .p-business_contents_wrap {
      width: rem(334);
      transition: width .6s ease .6s;

      @include mediaQueryFor(tablet) {
        width: rem(608);
      }

      @include mediaQueryFor(pc) {
        width: width(7);
      }

      @media print {
        width: width(7);
      }
    }
  }

  @media print {
    &_contents_wrap {
      width: width(7);
    }
  }
}
