@charset "UTF-8";
:root {
  --font-title: Midashi Go MB31, 游ゴシック体, YuGothic, 游ゴシック, Yu Gothic, sans-serif;
  --font-title--en: Optima Nova, 游ゴシック体, YuGothic, 游ゴシック, Yu Gothic, sans-serif;
  --font-link: Helvetica Neue, 游ゴシック体, YuGothic, 游ゴシック, Yu Gothic, sans-serif;
  --text-title--lg: 3.25rem;
  --text-title: 1.5625rem;
  --text-title--en: 1.5625rem;
  --text-headline: 1.25rem;
  --text-headline--en: 1.375rem;
  --text-headline--sm: 1.25rem;
  --text-default: 1rem;
  --text-small: 0.8125rem;
  --weight-default: normal;
  --weight-title: normal;
  --line-title--lg: 1.5;
  --line-title: 1.5;
  --line-title--en: 1.5;
  --line-headline: 1.5;
  --line-headline--en: 1.5;
  --line-headline--sm: 1.5;
  --line-default: 2;
  --line-small: 2;
  --letter-title--lg: 0.24938rem;
  --letter-title: 0.06rem;
  --letter-title--en: 0.06rem;
  --letter-headline: 0.0125rem;
  --letter-headline--en: 0.01375rem;
  --letter-headline--sm: 0.0125rem;
  --letter-default: normal;
  --letter-small: normal;
  --width-logo: 4.75rem;
  --height-logo: 5.125rem; }
  @media screen and (min-width: 1023px) {
    :root {
      --text-title--lg: 5.625rem;
      --text-title: 1.875rem;
      --text-title--en: 2rem;
      --text-headline: 1.375rem;
      --text-headline--en: 1.5rem;
      --text-headline--sm: 1.125rem;
      --text-default: 1.125rem;
      --text-small: 0.9375rem; } }
  @media screen and (min-width: 1023px) {
    :root {
      --weight-default: 500; } }
  @media screen and (min-width: 1023px) {
    :root {
      --line-title--lg: 7rem;
      --line-title: 1.5;
      --line-title--en: 1.3125;
      --line-headline: 1.5;
      --line-headline--en: 1.38;
      --line-headline--sm: 1.5;
      --line-default: 2;
      --line-small: 2; } }
  @media print {
    :root {
      --line-default: 1.8; } }
  @media screen and (min-width: 1023px) {
    :root {
      --letter-title--lg: 0.4375rem;
      --letter-title: 0.0375rem;
      --letter-title--en: 0.07125rem;
      --letter-headline: 0.04375rem;
      --letter-headline--en: 0.0475rem;
      --letter-headline--sm: 0.03562rem;
      --letter-default: 0.02813rem;
      --letter-small: 0.02375rem; } }
  @media screen and (min-width: 1023px) {
    :root {
      --width-logo: 5.25rem;
      --height-logo: 5.6875rem; } }

@font-face {
  font-family: "Optima nova";
  src: url("/fonts/optima.woff2") format("woff2"), url("/fonts/optima.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium"), local("YuGothic-Medium");
  font-display: swap; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold"), local("YuGothic-Bold");
  font-weight: bold;
  font-display: swap; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

:focus {
  outline: none;
  box-shadow: 0 0 0 0.125rem #e7e7e7; }

::selection {
  background-color: #e7e7e7; }

html,
body {
  width: 100%; }

body {
  color: #000;
  font-family: "Helvetica Neue", "Hiragino Sans W3", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: var(--text-default);
  font-weight: var(--weight-default);
  line-height: var(--line-default);
  letter-spacing: var(--letter-default);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media screen and (min-width: 1023px) {
    body {
      color: #282828;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif; } }
  @media print {
    body {
      color: #000;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif; } }

h1, h2, h3, h4, h5, h6 {
  color: #585858;
  font-weight: var(--weight-title); }

ul {
  list-style: none; }

a, button {
  text-decoration: none;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  cursor: pointer; }

a, button, span {
  color: inherit; }

button {
  border: none; }

img,
svg {
  width: 100%;
  height: 100%; }

svg:not([fill]) {
  fill: inherit; }

svg:not([stroke]) {
  stroke: inherit; }

.l-footer {
  text-align: center;
  padding-top: 2.5rem; }
  @media screen and (min-width: 767px) {
    .l-footer {
      padding-top: 5rem; } }
  .l-footer_contact {
    max-width: 50.75rem;
    margin-bottom: 2.5rem;
    opacity: 0;
    transform: scale(0.7); }
    @media screen and (min-width: 767px) {
      .l-footer_contact {
        margin: 0 auto 7.125rem auto; } }
    @media print {
      .l-footer_contact {
        margin: 0 auto; } }
    .l-footer_contact_inner {
      padding: 1.375rem 1.25rem 1.75rem 1.25rem;
      line-height: 1;
      text-align: center; }
      @media screen and (min-width: 767px) {
        .l-footer_contact_inner {
          padding: 4.125rem 2rem 3.5rem 2rem;
          cursor: pointer; } }
    @media screen and (min-width: 767px) {
      .l-footer_contact_title {
        font-size: var(--text-title--en);
        line-height: var(--line-title--en);
        letter-spacing: var(--letter-title--en); } }
    .l-footer_contact_message {
      margin-top: 1.3125rem;
      margin-bottom: 1.5rem; }
      @media screen and (min-width: 767px) {
        .l-footer_contact_message {
          margin-top: 1.6875rem;
          margin-bottom: 1.4375rem; }
          .l-footer_contact_message br {
            display: none; } }
      @media print {
        .l-footer_contact_message br {
          display: none; } }
    .l-footer_contact[data-loaded="true"] {
      opacity: 1;
      transform: scale(1);
      transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .l-footer_copyright {
    margin-top: 1.9375rem;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    font-family: var(--font-title--en);
    letter-spacing: 0.02187rem;
    line-height: 1; }
  .l-footer_cursor-stalker {
    display: none; }
    @media screen and (min-width: 1023px) {
      .l-footer_cursor-stalker[data-move="true"] {
        display: block;
        pointer-events: none;
        position: fixed;
        top: -1rem;
        left: -1rem;
        width: 2rem;
        height: 2rem;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        transform: translate(0, 0);
        transition: .5s, transform .2s;
        transition-timing-function: ease-out;
        z-index: 998; }
        .l-footer_cursor-stalker[data-move="true"][data-hover="true"] {
          top: -4rem;
          left: -4rem;
          width: 6rem;
          height: 6rem; }
          .l-footer_cursor-stalker[data-move="true"][data-hover="true"][data-focused="true"] {
            top: 0rem;
            left: 0rem;
            width: 0rem;
            height: 0rem; } }

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 3.9375rem 4rem 1rem 4rem;
  transition: opacity 1.2s ease .2s;
  z-index: 998; }
  @media screen and (min-width: 1023px) {
    .l-header {
      top: 3.5rem;
      padding: 0 5rem;
      background-color: transparent;
      box-shadow: none; } }
  @media screen and (min-width: 1387px) {
    .l-header {
      left: calc(50% - 44.25rem - 0.625rem);
      padding: 0 6.5rem; } }
  @media print {
    .l-header {
      padding: 2.5rem 1.25rem; } }
  .l-header_logo {
    width: var(--width-logo);
    fill: #707070; }
    .l-header_logo_mark {
      height: var(--height-logo); }
    .l-header_logo_ja {
      height: 0.6875rem;
      margin-top: 0.6875rem; }
      @media screen and (min-width: 1023px) {
        .l-header_logo_ja {
          height: 0.75rem;
          transition: .5s ease; } }
  .l-header[data-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s ease, opacity .5s ease; }
    @media screen and (min-width: 1023px) {
      .l-header[data-hidden="true"] {
        visibility: visible;
        opacity: 1; }
        .l-header[data-hidden="true"] .l-header_logo_ja {
          visibility: hidden;
          opacity: 0; } }
    @media print {
      .l-header[data-hidden="true"] {
        position: absolute;
        visibility: visible;
        opacity: 1; } }

.l-nav {
  bottom: 0;
  right: 0;
  overflow: visible;
  z-index: 997; }
  @media screen and (min-width: 1023px) {
    .l-nav {
      top: 0;
      width: 5rem;
      height: 100vh; } }
  @media print {
    .l-nav {
      display: none; } }
  .l-nav::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 11.25rem;
    box-shadow: 0 0 2rem 2rem #fff;
    visibility: hidden;
    transition: visibility 0; }
    @media screen and (min-width: 767px) {
      .l-nav::after {
        width: calc(100% - 7.5rem); } }
  @media screen and (min-width: 1023px) {
    .l-nav_sidebar {
      width: 100%;
      height: 100%;
      padding: 1rem;
      border-left: 1px solid #cfcfcf;
      background-color: #fff; } }
  .l-nav_button {
    margin-bottom: calc(1.9375rem + env(safe-area-inset-bottom));
    margin-right: 1.25rem;
    width: 3.25rem;
    height: 3.25rem;
    color: #585858;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.24);
    z-index: 99; }
    .l-nav_button:focus {
      box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.24), 0 0 0 0.125rem #e7e7e7; }
    @media screen and (min-width: 1023px) {
      .l-nav_button {
        width: 3rem;
        height: 4.5rem;
        margin: 0;
        text-align: center;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0; }
        .l-nav_button:focus {
          box-shadow: 0 0 0 0.125rem #e7e7e7; } }
    .l-nav_button_inner {
      padding: .875rem;
      border-radius: 50%; }
      @media screen and (min-width: 1023px) {
        .l-nav_button_inner {
          padding: 0.25rem;
          border-radius: 0; } }
    .l-nav_button_hamburger {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 3px 2px;
      margin-left: auto;
      margin-right: auto; }
      @media screen and (min-width: 1023px) {
        .l-nav_button_hamburger {
          width: 2.25rem;
          height: 2.25rem; } }
      .l-nav_button_hamburger::before,
      .l-nav_button_hamburger span, .l-nav_button_hamburger::after {
        height: 10%;
        margin: 0 auto;
        background-color: currentColor;
        transform-origin: center;
        transition: .15s; }
    .l-nav_button_label {
      display: none; }
      @media screen and (min-width: 1023px) {
        .l-nav_button_label {
          display: block;
          width: 3rem;
          margin-top: -0.25rem;
          margin-left: -0.25rem;
          font-family: var(--font-title--en);
          font-size: 0.75rem;
          line-height: 2.75;
          letter-spacing: 0.08rem;
          animation: nav-fade-out-in .6s ease-in-out; } }
  .l-nav_contents {
    top: calc(5.125rem + env(safe-area-inset-bottom) - 100vh);
    right: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    filter: blur(24px);
    backdrop-filter: blur(24px);
    overflow-y: scroll;
    z-index: -1;
    -ms-overflow-style: none;
    transition: visibility .5s ease, opacity .5s ease; }
    @media screen and (min-width: 1023px) {
      .l-nav_contents {
        top: 0;
        right: auto;
        left: calc(5rem - 100vw);
        width: calc(100vw - 5rem); } }
    .l-nav_contents::-webkit-scrollbar {
      width: 0; }
  .l-nav_links {
    margin: 13.625rem 3rem 5rem 3rem; }
    @media screen and (min-width: 767px) {
      .l-nav_links {
        width: 27.75rem;
        margin: 13.5rem auto 7.5rem auto;
        padding-left: 8.25rem; } }
  .l-nav_link {
    margin-top: 1.625rem; }
    .l-nav_link + .l-nav_link {
      margin-top: 0.75rem; }
      @media screen and (min-width: 767px) {
        .l-nav_link + .l-nav_link {
          margin-top: 1.75rem; } }
    .l-nav_link:nth-child(1) .c-curtain::before {
      transition-delay: .1s; }
    .l-nav_link:nth-child(2) .c-curtain::before {
      transition-delay: .2s; }
    .l-nav_link:nth-child(3) .c-curtain::before {
      transition-delay: .3s; }
    .l-nav_link:nth-child(4) .c-curtain::before {
      transition-delay: .4s; }
    .l-nav_link:nth-child(5) .c-curtain::before {
      transition-delay: .5s; }
    .l-nav_link_title {
      height: 3.875rem;
      padding: 1rem 3.375rem 1rem 1rem;
      cursor: pointer; }
      @media screen and (min-width: 767px) {
        .l-nav_link_title {
          height: 4.625rem;
          padding-right: 4.375rem; } }
  .l-nav .c-accordion input:checked + label + .c-accordion_menu {
    visibility: hidden; }
  .l-nav[aria-expanded="true"]::after {
    visibility: visible;
    transition: visibility .5s ease 1s; }
  .l-nav[aria-expanded="true"] .l-nav_contents {
    visibility: visible;
    opacity: 1;
    filter: blur(0);
    transition: visibility .5s ease, filter .5s ease, opacity 1s ease; }
  .l-nav[aria-expanded="true"] .l-nav_button_label {
    animation: nav-fade-in-out .6s ease-in-out; }
  .l-nav[aria-expanded="true"] .l-nav_button_hamburger::before, .l-nav[aria-expanded="true"] .l-nav_button_hamburger::after {
    width: 102.05%;
    transition: .15s .15s;
    transform-origin: center center; }
    @media screen and (min-width: 1023px) {
      .l-nav[aria-expanded="true"] .l-nav_button_hamburger::before, .l-nav[aria-expanded="true"] .l-nav_button_hamburger::after {
        width: 45px; } }
  .l-nav[aria-expanded="true"] .l-nav_button_hamburger::before {
    transform: translateY(8px) rotate(45deg); }
    @media screen and (min-width: 1023px) {
      .l-nav[aria-expanded="true"] .l-nav_button_hamburger::before {
        transform: translate(-6px, 14px) rotate(45deg); } }
  .l-nav[aria-expanded="true"] .l-nav_button_hamburger span {
    width: 0;
    transition: .15s; }
  .l-nav[aria-expanded="true"] .l-nav_button_hamburger::after {
    transform: translateY(-8px) rotate(-45deg); }
    @media screen and (min-width: 1023px) {
      .l-nav[aria-expanded="true"] .l-nav_button_hamburger::after {
        transform: translate(-6px, -14px) rotate(-45deg); } }
  .l-nav[aria-expanded="true"] .c-accordion input:checked + label + .c-accordion_menu {
    visibility: visible; }

@keyframes nav-fade-out-in {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes nav-fade-in-out {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.l-main, .l-footer {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 3.5rem; }
  @media screen and (min-width: 767px) {
    .l-main, .l-footer {
      padding-left: 5rem;
      padding-right: 5rem; } }
  @media screen and (min-width: 1023px) {
    .l-main, .l-footer {
      padding-left: 7.5rem;
      padding-right: 7.5rem;
      padding-bottom: 0; } }
  @media print {
    .l-main, .l-footer {
      padding-bottom: 0; } }
  .l-main.--with-hero--profile, .--with-hero--profile.l-footer {
    margin-top: calc(100vh - 10.5rem);
    padding-top: 1.5625rem; }
    @media screen and (min-width: 767px) {
      .l-main.--with-hero--profile, .--with-hero--profile.l-footer {
        margin-top: 0;
        padding-top: 0; } }
    @media print {
      .l-main.--with-hero--profile, .--with-hero--profile.l-footer {
        margin-top: 13.75rem; } }
  .l-main.--with-hero--business, .--with-hero--business.l-footer {
    margin-top: calc(100vh - 8.75rem);
    padding-top: 1.5625rem; }
    @media screen and (min-width: 767px) {
      .l-main.--with-hero--business, .--with-hero--business.l-footer {
        margin-top: 0;
        padding-top: 0; } }
    @media print {
      .l-main.--with-hero--business, .--with-hero--business.l-footer {
        margin-top: 13.75rem; } }

.c-text-default {
  font-size: var(--text-default);
  font-weight: var(--weight-default);
  line-height: var(--line-default);
  letter-spacing: var(--letter-default); }

.c-text-small, .c-dl dt {
  font-size: var(--text-small);
  letter-spacing: var(--letter-small);
  line-height: var(--line-small); }

.c-button {
  display: block;
  width: 16.25rem;
  height: 3rem;
  padding: 0.375rem 0 0.625rem 0;
  text-align: center;
  border-radius: 1.5rem; }
  .c-button::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    box-shadow: 0.25rem 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.24); }
  .c-button:hover {
    filter: contrast(0.4); }
  .c-button:active {
    filter: contrast(1);
    transform: translateY(0.25rem); }
    .c-button:active::before {
      box-shadow: 0.25rem 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.24); }
  @media print {
    .c-button {
      display: none; } }

.c-image {
  object-fit: cover;
  object-position: center; }

.c-curtain {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden; }
  .c-curtain::before, .c-curtain::after {
    top: 0;
    left: 0; }
  .c-curtain::before {
    background-color: #e7e7e7;
    box-shadow: -2rem 0 2rem 0 #e7e7e7;
    z-index: 1; }
  .c-curtain::after {
    background-image: linear-gradient(245deg, #fff 20%, rgba(255, 255, 255, 0) 20%);
    z-index: 2; }
  .c-curtain.--white::before {
    background-color: #fff;
    box-shadow: -2rem 0 2rem 0 #fff; }
  .c-curtain.--white::after {
    display: none; }

[aria-expanded="true"] .c-curtain,
[data-loaded="true"] .c-curtain {
  visibility: hidden; }
  [aria-expanded="true"] .c-curtain::before, [aria-expanded="true"] .c-curtain::after,
  [data-loaded="true"] .c-curtain::before,
  [data-loaded="true"] .c-curtain::after {
    visibility: visible;
    transform: translateX(calc(100% + 4rem)); }
  [aria-expanded="true"] .c-curtain::before,
  [data-loaded="true"] .c-curtain::before {
    transition: transform .6s ease .2s; }
  [aria-expanded="true"] .c-curtain::after,
  [data-loaded="true"] .c-curtain::after {
    transition: transform .2s ease; }

@media print {
  .c-curtain {
    display: none; } }

.c-dl {
  width: calc(100% + 1.25rem);
  margin-right: 0;
  margin-left: auto; }
  @media screen and (min-width: 767px) {
    .c-dl {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 66.9375rem; } }
  @media print {
    .c-dl {
      display: flex;
      flex-wrap: wrap;
      width: 100%; } }
  .c-dl dt {
    width: 100%;
    padding-top: 0.6875rem;
    font-weight: inherit;
    border-top: 1px solid #cfcfcf; }
    @media screen and (min-width: 767px) {
      .c-dl dt {
        padding: 0.875rem 0 0.9375rem 0; }
        .c-dl dt:nth-last-child(2) {
          border-bottom: 1px solid #cfcfcf; } }
    @media print {
      .c-dl dt {
        width: 12.5rem; }
        .c-dl dt:nth-last-child(2) {
          border-bottom: 1px solid #cfcfcf; } }
  .c-dl dd {
    padding-bottom: 0.5625rem; }
    @media screen and (min-width: 767px) {
      .c-dl dd {
        padding: 0.6875rem 0 0.75rem 0;
        border-top: 1px solid #cfcfcf; } }
    @media print {
      .c-dl dd {
        width: calc(100% - 12.5rem);
        padding-top: 0.5rem;
        border-top: 1px solid #cfcfcf; } }
    .c-dl dd:last-child {
      padding-bottom: 1.25rem; }
      @media screen and (min-width: 767px) {
        .c-dl dd:last-child {
          padding-bottom: 1.375rem; } }
  .c-dl li {
    margin-left: 1.25rem;
    list-style: disc; }

.c-title,
.c-headline {
  color: #585858;
  font-family: var(--font-title);
  font-weight: var(--weight-title); }
  .c-title--en, .c-headline-sp-title-pc, .p-profile_name_en, .c-title--lg,
  .c-headline--en,
  .l-footer_contact_title,
  .p-service_contents ol li::before,
  .c-headline--lg {
    font-family: var(--font-title--en);
    font-weight: var(--weight-title); }

.c-title {
  font-size: var(--text-title);
  letter-spacing: var(--letter-title);
  line-height: var(--line-title); }
  .c-title--lg {
    display: inline-block;
    font-size: var(--text-title--lg);
    letter-spacing: var(--letter-title--lg);
    line-height: var(--line-title--lg);
    text-align: left; }

.c-headline {
  font-size: var(--text-headline);
  letter-spacing: var(--letter-headline);
  line-height: var(--line-headline); }
  
  .c-headline--en,
  .l-footer_contact_title,
  .p-service_contents ol li::before {
    font-size: var(--text-headline--en);
    letter-spacing: var(--letter-headline--en);
    line-height: var(--line-headline--en); }

.c-headline-sp-title-pc, .p-profile_name_en {
  font-size: var(--text-headline--en);
  line-height: var(--line-headline--en);
  letter-spacing: var(--letter-headline--en); }
  @media screen and (min-width: 1023px) {
    .c-headline-sp-title-pc, .p-profile_name_en {
      font-size: var(--text-title--en);
      line-height: var(--line-title--en);
      letter-spacing: var(--letter-title--en); } }

.c-arrow {
  display: inline-block;
  font-size: 0;
  letter-spacing: 0;
  line-height: 1; }
  .c-arrow::before {
    content: "";
    width: 5.0625rem;
    height: 0.9375rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2281%22%20height%3D%2215%22%20viewBox%3D%220%200%2081%2015%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M77.9412%2013.6722L65.1077%200.838713L65.798%200.148438L80.298%2014.6484H0V13.6722H77.9412Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E"); }
  .c-arrow span {
    margin-left: 0.625rem;
    font-family: var(--font-link);
    font-size: 0.9375rem;
    font-weight: normal;
    letter-spacing: var(--letter-small);
    line-height: var(--line-small); }
  .c-arrow--reverse {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: baseline; }
    .c-arrow--reverse::before {
      transform: rotateY(180deg); }
    .c-arrow--reverse span {
      margin-right: 0.625rem; }
  @media print {
    .c-arrow {
      display: none; } }

.c-accordion label::before, .c-accordion label::after {
  content: "";
  top: 1.875rem;
  left: 11rem;
  width: 1.25rem;
  height: 1px;
  background-color: #585858; }
  @media screen and (min-width: 1023px) {
    .c-accordion label::before, .c-accordion label::after {
      top: 2.1875rem;
      left: auto;
      right: 1.5rem; } }

.c-accordion label::after {
  transform: rotate(90deg); }

.c-accordion_menu {
  height: 0;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem); }
  @media screen and (min-width: 1023px) {
    .c-accordion_menu {
      padding: 0 2.5rem; } }
  @media screen and (min-width: 1023px) {
    .c-accordion_menu li + li {
      margin-top: 0.25rem; } }
  .c-accordion_menu li a {
    padding: 0.25rem 1rem; }
    @media screen and (min-width: 1023px) {
      .c-accordion_menu li a {
        padding: 0.5rem 1rem; } }

.c-accordion input {
  display: none; }
  .c-accordion input:checked + label::after {
    transform: rotate(90deg) scaleX(0); }
  .c-accordion input:checked + label + .c-accordion_menu {
    height: 4.875rem;
    padding: 0.625rem 0.875rem;
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
    @media screen and (min-width: 1023px) {
      .c-accordion input:checked + label + .c-accordion_menu {
        height: 8.875rem;
        padding: 1.625rem 2.5rem; } }

.c-title-animation {
  width: 18.125rem; }
  @media screen and (min-width: 1023px) {
    .c-title-animation {
      width: 29.1875rem; } }
  .c-title-animation.--whoweare {
    transform: translateX(1.25rem);
    height: 7.375rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--whoweare {
        height: 11.875rem;
        transform: translateX(1rem); } }
  .c-title-animation.--outline {
    height: 3.18289rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--outline {
        height: 5.125rem; } }
  .c-title-animation.--whatwedo {
    height: 7.56908rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--whatwedo {
        height: 12.1875rem; } }
  .c-title-animation.--policy {
    width: 15.33058rem;
    height: 8.75rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--policy {
        display: none; } }
    .c-title-animation.--policy--pc {
      display: none; }
      @media screen and (min-width: 1023px) {
        .c-title-animation.--policy--pc {
          display: block;
          width: 49.5625rem;
          height: 6.8125rem; } }
  .c-title-animation.--contact, .c-title-animation.--confirm, .c-title-animation.--thank {
    height: 4.23046rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--contact, .c-title-animation.--confirm, .c-title-animation.--thank {
        height: 6.8125rem; } }
  .c-title-animation.--thank {
    width: 100%;
    max-width: 22.35546rem; }
    @media screen and (min-width: 1023px) {
      .c-title-animation.--thank {
        width: 36rem; } }
  .c-title-animation.--confirm, .c-title-animation.--thank {
    top: 0;
    left: 0; }
  .c-title-animation_line {
    fill: rgba(88, 88, 88, 0);
    stroke: #585858;
    stroke-dasharray: 550 550; }
    .c-title-animation_line.--short {
      stroke-dasharray: 300 300; }
    .c-title-animation_line.--shorter {
      stroke-dasharray: 200 200; }
  .c-title-animation:not([data-loaded="true"]) .c-title-animation_line {
    fill: rgba(88, 88, 88, 0);
    stroke: #585858;
    stroke-dashoffset: 550;
    transition: none; }
    .c-title-animation:not([data-loaded="true"]) .c-title-animation_line.--short {
      stroke-dashoffset: -300; }
    .c-title-animation:not([data-loaded="true"]) .c-title-animation_line.--shorter {
      stroke-dashoffset: -200; }
  .c-title-animation[data-loaded="true"] .c-title-animation_line {
    stroke: rgba(88, 88, 88, 0);
    stroke-dashoffset: -550;
    transition: stroke-dashoffset 1s ease, stroke .1s ease .9s; }
    .c-title-animation[data-loaded="true"] .c-title-animation_line.--short {
      stroke-dashoffset: 300; }
    .c-title-animation[data-loaded="true"] .c-title-animation_line.--shorter {
      stroke-dashoffset: 200; }
    .c-title-animation[data-loaded="true"] .c-title-animation_line.--late {
      transition: stroke-dashoffset 1s ease .2s, stroke .1s ease 1.1s; }
    .c-title-animation[data-loaded="true"] .c-title-animation_line.--fill {
      fill: #585858;
      transition: stroke-dashoffset 1s ease .3s, stroke .1s ease 1.2s, fill 1s ease .5s; }
  .c-title-animation[aria-hidden="true"] {
    visibility: hidden; }
  @media print {
    .c-title-animation .c-title-animation_line {
      stroke: rgba(88, 88, 88, 0); }
      .c-title-animation .c-title-animation_line.--fill {
        fill: #585858; } }

.c-text-incoming {
  color: transparent;
  background-image: linear-gradient(90deg, #282828 90%, rgba(40, 40, 40, 0) calc(90% + 64px));
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: right -100% center; }
  .c-text-incoming.--title {
    background-image: linear-gradient(90deg, #585858 90%, rgba(40, 40, 40, 0) calc(90% + 64px)); }

[data-loaded="true"] .c-text-incoming {
  background-position: right calc(20% - 64px) center;
  transition: background-position 1s ease; }

@media print {
  .c-text-incoming {
    color: currentColor;
    background: none; }
    .c-text-incoming.--title {
      background: none; } }

.p-about {
  padding-top: 5.3125rem;
  padding-bottom: 4.5rem; }
  @media screen and (min-width: 767px) {
    .p-about {
      padding-top: 12.25rem;
      padding-bottom: 12.5rem; } }
  .p-about_title {
    padding: 0.75rem 0 0.625rem 0;
    text-align: right; }
    @media screen and (min-width: 1023px) {
      .p-about_title {
        padding: 1.8125rem 0 0.625rem 0; } }
  .p-about_message {
    margin-top: 7.1875rem; }
    @media screen and (min-width: 767px) {
      .p-about_message {
        margin-top: 2.25rem; } }
    .p-about_message_tagline {
      margin-bottom: 1.625rem;
      font-size: var(--text-headline);
      letter-spacing: var(--letter-headline);
      line-height: 2; }
      @media screen and (min-width: 767px) {
        .p-about_message_tagline {
          margin-bottom: 3rem; } }
      @media screen and (min-width: 1023px) {
        .p-about_message_tagline {
          margin-bottom: 4.375rem;
          font-size: var(--text-title);
          letter-spacing: var(--letter-title);
          line-height: var(--line-title); } }
    .p-about_message p + p {
      margin-top: calc(var(--line-default) * var(--text-default)); }
  .p-about_member {
    display: block;
    margin-top: 4.25rem; }
    @media screen and (min-width: 767px) {
      .p-about_member {
        display: flex;
        margin-top: 10rem; } }
    @media print {
      .p-about_member {
        display: flex;
        margin-top: 5rem; } }
    .p-about_member_image {
      width: 17.5rem;
      margin: 0 auto;
      z-index: -1; }
      @media screen and (min-width: 767px) {
        .p-about_member_image {
          width: 38.83%;
          min-width: 17.5rem;
          max-width: 24.75rem;
          margin: 0 1.25rem 0 0; } }
      @media print {
        .p-about_member_image {
          width: 38.83%;
          min-width: 17.5rem;
          max-width: 24.75rem;
          margin: 0 1.25rem 0 0; } }
      .p-about_member_image picture {
        padding-bottom: 100%; }
    .p-about_member_contents {
      margin: 1.25rem 0.5rem 0 0.5rem; }
      @media screen and (min-width: 767px) {
        .p-about_member_contents {
          flex-grow: 1;
          max-width: 37.75rem;
          margin: 1.25rem 0 0 0;
          transform: translateX(-3rem); } }
      @media print {
        .p-about_member_contents {
          flex-grow: 1;
          max-width: 37.75rem;
          margin: 1.25rem 0 0 0;
          transform: translateX(-3rem); } }
      .p-about_member_contents p {
        margin: 1.3125rem 0 1.8125rem 0; }
        @media screen and (min-width: 767px) {
          .p-about_member_contents p {
            margin: 0.625rem 0 1.6875rem 0; } }
        @media print {
          .p-about_member_contents p {
            margin: 0.625rem 0 1.6875rem 0; } }
    .p-about_member_name span:first-child {
      margin-bottom: 0.25rem; }
      @media screen and (min-width: 767px) {
        .p-about_member_name span:first-child {
          margin-bottom: 0; } }
    .p-about_member[data-right] {
      margin-top: 4.5rem; }
      @media screen and (min-width: 767px) {
        .p-about_member[data-right] {
          margin-top: 10rem;
          margin-right: 0;
          transform: translateX(3rem); } }
      @media print {
        .p-about_member[data-right] {
          margin-top: 7.5rem;
          margin-right: 0;
          transform: translateX(3rem); } }
  .p-about[data-loaded="true"] .p-about_message .c-curtain::before {
    transition: transform 1.5s ease; }

@media screen and (min-width: 767px) {
  .p-business {
    position: relative;
    padding: 0 5rem; } }

.p-business_header {
  width: 100%;
  height: 58.6957vw; }
  @media screen and (min-width: 767px) {
    .p-business_header {
      position: absolute;
      top: 0;
      left: 0;
      height: 25rem; } }
  @media screen and (min-width: 1023px) {
    .p-business_header {
      left: -2.625rem;
      max-width: calc(50vw + 24.75rem + 0.625rem);
      height: 37.5rem; } }
  @media print {
    .p-business_header {
      height: 12.5rem; } }
  .p-business_header_tagline {
    height: 58.6957vw;
    padding-top: 5.625rem;
    text-align: center;
    background-position: center;
    background-size: cover; }
    @media screen and (min-width: 767px) {
      .p-business_header_tagline {
        height: 25rem;
        padding-top: 10rem; } }
    @media screen and (min-width: 1023px) {
      .p-business_header_tagline {
        height: 37.5rem;
        margin-right: 1rem;
        padding-top: 15.375rem; } }
    @media print {
      .p-business_header_tagline {
        height: 12.5rem;
        padding-top: 4.5rem; } }
    .p-business_header_tagline svg,
    .p-business_header_tagline span {
      display: block;
      text-align: center; }
    .p-business_header_tagline svg {
      height: 1.375rem;
      margin: 0 auto 0.5rem auto;
      fill: #fff; }
      @media screen and (min-width: 767px) {
        .p-business_header_tagline svg {
          width: 20.3125rem;
          height: 2rem;
          margin-bottom: 1.125rem; } }
    .p-business_header_tagline span {
      margin-right: 0.5rem;
      color: currentColor;
      font-family: var(--font-title--en);
      font-weight: var(--weight-title); }
      @media screen and (min-width: 767px) {
        .p-business_header_tagline span {
          margin-right: 1rem;
          font-size: var(--text-headline--en);
          line-height: var(--line-headline--en);
          letter-spacing: var(--letter-headline--en); } }

.p-business_contents {
  margin-top: -2.25rem;
  padding: 0 1.25rem; }
  @media screen and (min-width: 767px) {
    .p-business_contents {
      margin: 0;
      padding: 21.25rem 0 0 0; } }
  @media screen and (min-width: 1023px) {
    .p-business_contents {
      padding: 27.5rem 0 0 0; } }
  @media print {
    .p-business_contents {
      margin-top: -1rem; } }
  .p-business_contents_wrap {
    width: 0;
    overflow: hidden; }
    .p-business_contents_wrap::after {
      top: 0;
      right: 0;
      width: 2rem;
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 90%); }
      @media screen and (min-width: 767px) {
        .p-business_contents_wrap::after {
          width: 3rem; } }
  .p-business_contents_inner {
    width: 20.875rem;
    padding: 1.25rem; }
    @media screen and (min-width: 767px) {
      .p-business_contents_inner {
        width: 38rem;
        margin-top: 0;
        padding: 3.75rem 3.75rem 3.28125rem 3.75rem; } }
    @media screen and (min-width: 1023px) {
      .p-business_contents_inner {
        width: 44.25rem;
        height: 39rem; } }
    @media print {
      .p-business_contents_inner {
        width: 44.25rem; } }
    .p-business_contents_inner p + p {
      margin-top: calc(var(--line-small) * var(--text-small)); }
      @media screen and (min-width: 767px) {
        .p-business_contents_inner p + p {
          margin-top: calc(var(--line-default) * var(--text-default)); } }
      @media print {
        .p-business_contents_inner p + p {
          margin-top: 1rem; } }
    .p-business_contents_inner p:nth-last-child(2) {
      margin-bottom: 1.71875rem; }

.p-business.--shinichi {
  margin-top: -2.5rem; }
  @media screen and (min-width: 767px) {
    .p-business.--shinichi {
      position: relative; } }
  @media screen and (min-width: 1023px) {
    .p-business.--shinichi {
      margin-top: -3.75rem; } }
  .p-business.--shinichi .p-business_header_tagline {
    background-image: url("/images/top-business-shinichi-pc.jpg"); }
  .p-business.--shinichi .p-business_contents_wrap {
    margin-left: calc(100% - 20.875rem); }
    @media screen and (min-width: 767px) {
      .p-business.--shinichi .p-business_contents_wrap {
        margin-left: calc(100% - 38rem); } }
    @media screen and (min-width: 1023px) {
      .p-business.--shinichi .p-business_contents_wrap {
        margin-left: calc(100% - 44.25rem); } }
    @media print {
      .p-business.--shinichi .p-business_contents_wrap {
        margin-left: calc(100% - 44.25rem); } }

.p-business.--naoko {
  margin-top: 3.25rem; }
  @media screen and (min-width: 767px) {
    .p-business.--naoko {
      margin-top: 5rem; } }
  @media screen and (min-width: 1023px) {
    .p-business.--naoko {
      margin-top: 7.5rem; } }
  @media print {
    .p-business.--naoko {
      margin-top: 2rem; } }
  .p-business.--naoko .p-business_header {
    left: auto;
    right: 0; }
    .p-business.--naoko .p-business_header_tagline {
      background-image: url("/images/top-business-naoko-pc.jpg"); }

.p-business[data-loaded="true"] .p-business_contents_wrap {
  width: 20.875rem;
  transition: width .6s ease .6s; }
  @media screen and (min-width: 767px) {
    .p-business[data-loaded="true"] .p-business_contents_wrap {
      width: 38rem; } }
  @media screen and (min-width: 1023px) {
    .p-business[data-loaded="true"] .p-business_contents_wrap {
      width: 44.25rem; } }
  @media print {
    .p-business[data-loaded="true"] .p-business_contents_wrap {
      width: 44.25rem; } }

@media print {
  .p-business_contents_wrap {
    width: 44.25rem; } }

.p-contact_header {
  padding-top: 9rem; }
  @media screen and (min-width: 767px) {
    .p-contact_header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 7.5rem; } }
  @media screen and (min-width: 1023px) {
    .p-contact_header {
      padding-top: 12.5rem; } }
  .p-contact_header p {
    margin-top: 3rem; }
    .p-contact_header p[aria-hidden="true"] {
      display: none; }

.p-contact_thank {
  display: none; }
  .p-contact_thank[data-sended="true"] {
    text-align: center;
    margin: 4.75rem 0 1.25rem 0; }
    @media screen and (min-width: 1023px) {
      .p-contact_thank[data-sended="true"] {
        margin: 6.75rem 0 6rem 0; } }
    .p-contact_thank[data-sended="true"] .p-contact_thank_text {
      margin-top: 2.125rem;
      margin-bottom: 2.875rem; }
      @media screen and (min-width: 1023px) {
        .p-contact_thank[data-sended="true"] .p-contact_thank_text {
          margin-top: 2.625rem;
          margin-bottom: 1.5rem; } }
    .p-contact_thank[data-sended="true"] .p-contact_thank_address {
      margin-bottom: 2.5rem; }
      @media screen and (min-width: 1023px) {
        .p-contact_thank[data-sended="true"] .p-contact_thank_address {
          margin-bottom: 4.375rem; } }
      .p-contact_thank[data-sended="true"] .p-contact_thank_address p {
        margin-top: 0.875rem; }
        @media screen and (min-width: 1023px) {
          .p-contact_thank[data-sended="true"] .p-contact_thank_address p {
            margin-top: 0.75rem; } }
    .p-contact_thank[data-sended="true"] .p-contact_thank_button {
      margin: 0 auto; }

.p-form {
  margin: 1.625rem auto 0 auto; }
  @media screen and (min-width: 767px) {
    .p-form {
      margin: 6.25rem auto 2.5rem auto; } }
  .p-form_input {
    margin: 0 -1.25rem 0 1.25rem; }
    @media screen and (min-width: 767px) {
      .p-form_input {
        margin-right: auto;
        margin-left: auto; } }
    .p-form_input::after {
      content: "";
      bottom: -1px;
      left: 0;
      height: 2px;
      background-color: #cfcfcf;
      transform-origin: left bottom;
      transform: scale(0.6, 0); }
    .p-form_input label {
      padding-top: 0.6875rem;
      vertical-align: top; }
      @media screen and (min-width: 767px) {
        .p-form_input label {
          width: 6.875rem; } }
      @media screen and (min-width: 1023px) {
        .p-form_input label {
          width: 12rem; } }
    .p-form_input input::placeholder,
    .p-form_input textarea::placeholder {
      color: #9f9f9f; }
    .p-form_input input:focus,
    .p-form_input textarea:focus {
      box-shadow: none; }
    .p-form_input p {
      display: none; }
    .p-form_input input,
    .p-form_input textarea,
    .p-form_input p {
      width: 100%;
      padding-bottom: 0.5625rem;
      color: inherit;
      outline: none; }
      @media screen and (min-width: 767px) {
        .p-form_input input,
        .p-form_input textarea,
        .p-form_input p {
          width: calc(100% - 7.25rem); } }
      @media screen and (min-width: 1023px) {
        .p-form_input input,
        .p-form_input textarea,
        .p-form_input p {
          width: calc(100% - 12.375rem); } }
    .p-form_input label,
    .p-form_input input,
    .p-form_input textarea {
      display: block; }
      @media screen and (min-width: 767px) {
        .p-form_input label,
        .p-form_input input,
        .p-form_input textarea {
          display: inline-block; } }
    @media screen and (min-width: 767px) {
      .p-form_input label,
      .p-form_input input,
      .p-form_input textarea,
      .p-form_input p {
        padding: 3.5625rem 0 0.375rem 0; } }
    .p-form_input[aria-invalid="true"] {
      color: #b60005; }
      .p-form_input[aria-invalid="true"]::after {
        background-color: #b60005; }
    .p-form_input:focus-within {
      color: #585858; }
      .p-form_input:focus-within::after {
        background-color: #585858; }
    .p-form_input[aria-invalid="true"]::after, .p-form_input:focus-within::after {
      transform: scale(1); }
    .p-form_input[aria-readonly="true"] {
      margin: 0;
      border-bottom: none; }
      @media screen and (min-width: 767px) {
        .p-form_input[aria-readonly="true"] {
          margin-right: auto;
          margin-left: auto; } }
      .p-form_input[aria-readonly="true"]:focus-within::after {
        transform: scale(0); }
      @media screen and (min-width: 767px) {
        .p-form_input[aria-readonly="true"] label {
          padding-bottom: 0; } }
      .p-form_input[aria-readonly="true"] p {
        display: inline-block;
        word-break: break-all;
        word-wrap: break-word; }
      .p-form_input[aria-readonly="true"] input,
      .p-form_input[aria-readonly="true"] textarea {
        display: none; }
      @media screen and (min-width: 767px) {
        .p-form_input[aria-readonly="true"] input,
        .p-form_input[aria-readonly="true"] textarea,
        .p-form_input[aria-readonly="true"] p,
        .p-form_input[aria-readonly="true"] label {
          padding-top: 1.375rem; } }
      .p-form_input[aria-readonly="true"] input,
      .p-form_input[aria-readonly="true"] textarea,
      .p-form_input[aria-readonly="true"] p {
        padding-bottom: 0; }
  .p-form_footer {
    margin: 2.5rem auto 0 auto;
    text-align: center; }
    @media screen and (min-width: 767px) {
      .p-form_footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: center;
        margin: 5rem auto 0 auto; } }
  .p-form_button {
    margin: 0 auto 3rem auto;
    padding-bottom: 0.625rem;
    font-size: 1rem; }
    @media screen and (min-width: 767px) {
      .p-form_button {
        margin: 0 calc(8% + 8.5rem) 0 8%; } }
    @media screen and (min-width: 1023px) {
      .p-form_button {
        margin: 0 calc(18.82% + 8.5rem) 0 18.82%; } }
    .p-form_button:disabled {
      pointer-events: none;
      opacity: .3; }
  .p-form_back {
    cursor: pointer; }
    .p-form_back[aria-hidden="true"] {
      display: none; }
  .p-form[aria-hidden="true"] {
    display: none; }
  .p-form[aria-readonly="true"] {
    margin-top: 2.75rem; }
    @media screen and (min-width: 767px) {
      .p-form[aria-readonly="true"] {
        margin-top: 8.3125rem; } }

.p-hero {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 25rem;
  padding: 1.75rem 1.875rem 0 1.875rem;
  z-index: -1; }
  @media screen and (min-width: 767px) {
    .p-hero {
      position: relative;
      width: calc(100% - 5rem);
      padding: 0; } }
  @media print {
    .p-hero {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      width: 35rem;
      min-height: 15rem;
      padding: 0; } }
  .p-hero_bg {
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform .1s ease-out; }
    @media screen and (min-width: 767px) {
      .p-hero_bg {
        position: absolute;
        top: 0;
        left: 0; } }
  .p-hero.--business {
    height: calc(100vh - 8.75rem); }
    @media screen and (min-width: 767px) {
      .p-hero.--business {
        height: 37.5rem; } }
    @media screen and (min-width: 1023px) {
      .p-hero.--business {
        padding: 0 2.5rem 0 7.5rem; } }
    @media print {
      .p-hero.--business {
        height: 17.5rem; } }
    .p-hero.--business .p-hero_content {
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    .p-hero.--business .p-hero_title {
      text-align: center; }
      .p-hero.--business .p-hero_title svg {
        width: 17.875rem;
        height: 1.6875rem;
        margin-bottom: 0.875rem;
        fill: currentColor; }
        @media screen and (min-width: 767px) {
          .p-hero.--business .p-hero_title svg {
            width: 21.875rem;
            height: 1.875rem;
            margin-bottom: 1.1875rem;
            margin-right: 0.75rem; } }
      @media screen and (min-width: 767px) {
        .p-hero.--business .p-hero_title span {
          margin-right: 2.125rem; } }
  .p-hero.--profile {
    height: calc(100vh - 10.5rem); }
    @media screen and (min-width: 767px) {
      .p-hero.--profile {
        max-width: 89.75rem;
        height: 37.5rem;
        margin: 0 auto; } }
    @media print {
      .p-hero.--profile {
        height: 17.5rem; } }
    .p-hero.--profile .p-hero_bg {
      box-shadow: 0.625rem 5rem 3.75rem 0 rgba(0, 0, 0, 0.08); }
      @media screen and (min-width: 767px) {
        .p-hero.--profile .p-hero_bg {
          max-width: 63.75rem;
          left: auto;
          right: -5rem; } }
      @media screen and (min-width: 1023px) {
        .p-hero.--profile .p-hero_bg {
          right: -2.5rem; } }
  .p-hero--policy {
    height: auto; }

@media screen and (min-width: 767px) {
  .p-outline {
    padding-left: 5.25rem; } }

@media print {
  .p-outline {
    margin-top: 5rem; } }

.p-outline_title {
  text-align: right; }
  @media screen and (min-width: 767px) {
    .p-outline_title {
      margin-right: 2rem; } }

.p-outline_content {
  margin-top: 3rem; }
  @media screen and (min-width: 767px) {
    .p-outline_content {
      margin-top: 5.25rem; }
      .p-outline_content .c-dl dt {
        width: 7.5rem; }
      .p-outline_content .c-dl dd {
        width: calc(100% - 7.5rem);
        padding: 0.875rem 0 0.9375rem 0;
        font-size: var(--text-small);
        letter-spacing: var(--letter-small);
        line-height: var(--line-small); } }
  .p-outline_content .c-curtain {
    width: calc(100% + 1.25rem);
    margin-right: 0;
    margin-left: auto; }
    @media screen and (min-width: 767px) {
      .p-outline_content .c-curtain {
        width: 100%; } }
  .p-outline_content[data-loaded="true"] .c-curtain::before {
    transition: transform 1s ease; }

.p-policy_title {
  margin-top: 10rem; }
  @media screen and (min-width: 767px) {
    .p-policy_title {
      margin-top: 12.5rem; } }

.p-policy_contents {
  margin: 4.6875rem auto 5.5rem auto; }
  @media screen and (min-width: 767px) {
    .p-policy_contents {
      margin: 5.875rem auto 8rem auto; } }

.p-policy_headline {
  margin: 3.25rem 0 0.8125rem 0;
  font-size: var(--text-headline);
  letter-spacing: var(--letter-headline);
  line-height: var(--line-headline); }
  @media screen and (min-width: 767px) {
    .p-policy_headline {
      margin: 2.125rem 0 0.8125rem 0;
      font-size: var(--text-headline--sm);
      letter-spacing: var(--letter-headline--sm);
      line-height: var(--line-headline--sm); } }

.p-policy ul {
  list-style: disc;
  margin: 0.75rem 0 0.75rem 1.25rem; }
  @media screen and (min-width: 767px) {
    .p-policy ul {
      margin-top: 1.25rem; } }

@media screen and (min-width: 767px) {
  .p-profile {
    margin-top: -18.5rem; } }

@media print {
  .p-profile {
    margin-top: 0rem; } }

@media screen and (min-width: 767px) {
  .p-profile_section {
    margin-top: 5rem; } }

.p-profile_section p {
  margin-top: 2.375rem; }
  .p-profile_section p.c-text-incoming {
    transition-delay: .3s; }

@media screen and (min-width: 767px) {
  .p-profile_section[data-naoko] .p-profile_name {
    margin-bottom: 16.25rem; } }

@media screen and (min-width: 1023px) {
  .p-profile_section[data-naoko] .p-profile_name {
    margin-bottom: 18.875rem; } }

@media screen and (min-width: 767px) {
  .p-profile_section p + p {
    margin-top: calc(var(--line-default) * var(--text-default)); }
  .p-profile_section_text {
    max-width: 53.25rem;
    margin-left: auto;
    margin-right: auto; } }

.p-profile_name {
  max-width: 18.75rem;
  line-height: 1; }
  @media screen and (min-width: 767px) {
    .p-profile_name {
      margin-bottom: 13.75rem; } }
  .p-profile_name.c-text-incoming {
    transition-delay: .2s; }

.p-profile_headline {
  margin: 2.5rem 0; }
  @media screen and (min-width: 767px) {
    .p-profile_headline {
      max-width: 66.875rem;
      margin-left: auto; } }

@media screen and (min-width: 767px) {
  .p-profile_outline dl dt {
    width: 13.75rem; }
  .p-profile_outline dl dd {
    width: calc(100% - 13.75rem); } }

.p-profile_button {
  margin: 2.5rem auto; }
  @media screen and (min-width: 767px) {
    .p-profile_button {
      margin: 3.625rem auto 3.75rem auto; } }
  @media screen and (min-width: 1023px) {
    .p-profile_button {
      margin: 3.625rem auto 11.75rem auto; } }

.p-profile_homelink {
  margin: 5.75rem 0 0.5625rem 0;
  text-align: center; }
  @media screen and (min-width: 767px) {
    .p-profile_homelink {
      display: none; } }
  @media print {
    .p-profile_homelink {
      display: none; } }

.p-service + .p-service {
  margin: 5.5rem 0; }
  @media screen and (min-width: 1023px) {
    .p-service + .p-service {
      margin: 0; } }

@media screen and (min-width: 1023px) {
  .p-service {
    padding-top: 7rem; }
    .p-service:nth-last-child(2) {
      margin-bottom: 7.5rem; }
    .p-service_wrap {
      display: flex;
      justify-content: space-between; } }

.p-service_number span {
  opacity: 0; }

.p-service_number .c-title-animation {
  top: 0.375rem;
  left: 0;
  width: 4.8375rem;
  height: 4.0875rem; }
  @media screen and (min-width: 767px) {
    .p-service_number .c-title-animation {
      top: 0;
      width: 8.0625rem;
      height: 6.8125rem; } }
  .p-service_number .c-title-animation[data-loaded="true"] .c-title-animation_line {
    transition: stroke-dashoffset 1s ease .6s, stroke .1s ease 1.5s; }

.p-service_name {
  color: #585858;
  margin-bottom: 2.5rem; }
  @media screen and (min-width: 1023px) {
    .p-service_name {
      display: inline-block;
      min-width: 12rem;
      max-width: 20.25rem;
      margin-right: 2rem; } }
  .p-service_name span {
    display: block; }
    .p-service_name span:first-child {
      margin-right: 0.75rem; }
    @media screen and (min-width: 1023px) {
      .p-service_name span:nth-child(2) {
        margin-top: 0.375rem; } }
  .p-service_name small {
    font-size: var(--text-headline--sm);
    line-height: var(--line-headline--sm);
    letter-spacing: var(--letter-headline--sm); }
    @media screen and (min-width: 1023px) {
      .p-service_name small {
        margin-top: 0.3125rem; } }
  .p-service_name .c-curtain::before {
    transition-delay: .6s; }

.p-service_contents {
  width: 100%; }
  @media screen and (min-width: 1023px) {
    .p-service_contents {
      display: inline-block;
      max-width: 50.75rem;
      margin-top: 7.8125rem;
      margin-right: 0;
      padding: 0; } }
  .p-service_contents b {
    display: block;
    color: #585858;
    font-family: var(--font-title);
    font-weight: normal;
    font-size: var(--text-headline);
    line-height: var(--line-headline);
    letter-spacing: var(--letter-headline); }
    @media screen and (min-width: 767px) {
      .p-service_contents b {
        margin-bottom: 2.6875rem;
        font-size: var(--text-headline--sm);
        line-height: var(--line-headline--sm);
        letter-spacing: var(--letter-headline--sm); } }
  .p-service_contents p {
    margin: 1.25rem 0 1.875rem 0; }
    @media screen and (min-width: 767px) {
      .p-service_contents p {
        margin: 2.125rem 0 2.5rem 0; } }
  .p-service_contents ol {
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    padding: 2.125rem 1.25rem 2.5rem 3.5625rem;
    list-style: none;
    counter-reset: taskNum; }
    @media screen and (min-width: 767px) {
      .p-service_contents ol {
        width: 100%;
        margin-left: 0;
        padding: 2.5rem 1.25rem 2.5rem 4rem; } }
    @media screen and (min-width: 1023px) {
      .p-service_contents ol {
        padding-left: 5.25rem; } }
    .p-service_contents ol li {
      position: relative; }
      .p-service_contents ol li + li {
        margin-top: calc(var(--text-default) * var(--line-default)); }
        @media print {
          .p-service_contents ol li + li {
            margin-top: 1rem; } }
      .p-service_contents ol li::before {
        content: counter(taskNum) ".";
        position: absolute;
        top: 0.25rem;
        left: -2.3125rem;
        display: inline-block;
        counter-increment: taskNum;
        font-size: var(--text-default); }
        @media screen and (min-width: 767px) {
          .p-service_contents ol li::before {
            top: 0;
            left: -2rem;
            font-size: var(--text-headline--en); } }
        @media screen and (min-width: 1023px) {
          .p-service_contents ol li::before {
            left: -2.8125rem; } }
  .p-service_contents .c-curtain::before {
    transition-delay: 1s; }

.p-service[data-loaded="true"] .p-service_number span {
  opacity: 1;
  transition: opacity .5s ease .8s; }

.p-service[data-loaded="true"] .c-curtain::before {
  transition: transform .6s ease .6s; }

.p-service:not([data-loaded="true"]) .c-curtain::before {
  transform: translateX(0); }

@media print {
  .p-service .p-service_number span {
    opacity: 1; } }

.p-service--naoko .p-service_contents ol {
  background-color: #f6f1f1; }

.p-service--naoko .p-service_contents li::before {
  color: #b60005; }

.p-service--shinichi .p-service_contents ol {
  background-color: #f1f6f5; }
  .p-service--shinichi .p-service_contents ol li::before {
    color: #3f7095; }

.p-splash {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999; }
  .p-splash_tagline_animation {
    width: 15.625rem;
    height: 1.5rem;
    margin-left: 1rem; }
    @media screen and (min-width: 767px) {
      .p-splash_tagline_animation {
        width: 31.25rem;
        height: 3rem; } }
    .p-splash_tagline_animation svg {
      height: 100%;
      fill: #585858; }
    .p-splash_tagline_animation span {
      justify-content: flex-end;
      top: 0;
      left: -0.125rem; }
      .p-splash_tagline_animation span::before {
        width: 1px;
        background-color: #282828;
        opacity: 0; }
      .p-splash_tagline_animation span::after {
        flex-grow: 1; }
      .p-splash_tagline_animation span::before, .p-splash_tagline_animation span::after {
        height: 1.875rem; }
        @media screen and (min-width: 767px) {
          .p-splash_tagline_animation span::before, .p-splash_tagline_animation span::after {
            height: 3.375rem; } }
    .p-splash_tagline_animation:first-child {
      margin-left: 1.125rem;
      margin-bottom: 1.25rem; }
      @media screen and (min-width: 767px) {
        .p-splash_tagline_animation:first-child {
          margin-left: 1.25rem;
          margin-bottom: 2.5rem; } }
  .p-splash_logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0; }
    .p-splash_logo_mark, .p-splash_logo_ja {
      width: 10.625rem; }
      @media screen and (min-width: 767px) {
        .p-splash_logo_mark, .p-splash_logo_ja {
          width: 21.25rem; } }
    .p-splash_logo_mark {
      height: 10.625rem;
      margin-bottom: 1.25rem; }
      @media screen and (min-width: 767px) {
        .p-splash_logo_mark {
          height: 21.25rem;
          margin-bottom: 2.5rem; } }
      .p-splash_logo_mark::after {
        top: 0;
        left: 0;
        background-image: linear-gradient(#fff 80%, rgba(255, 255, 255, 0));
        transform-origin: right bottom;
        transform: scale(1.8) rotate(135deg) translate(50%, 100%); }
    .p-splash_logo_ja svg {
      fill: rgba(88, 88, 88, 0);
      stroke: #585858;
      stroke-width: .1; }
      .p-splash_logo_ja svg:first-child {
        height: 1.5rem; }
        @media screen and (min-width: 767px) {
          .p-splash_logo_ja svg:first-child {
            height: 3rem; } }
      .p-splash_logo_ja svg:last-child {
        top: -2rem;
        left: -1.5rem;
        width: 12rem;
        height: 4rem; }
        @media screen and (min-width: 767px) {
          .p-splash_logo_ja svg:last-child {
            top: -4.0625rem;
            left: -2.9375rem;
            width: 24rem;
            height: 8rem; } }
  .p-splash_stroke {
    stroke-dasharray: 15 15;
    stroke-dashoffset: -15; }
    .p-splash_stroke.--long {
      stroke-dasharray: 45 45;
      stroke-dashoffset: 45; }
  .p-splash[data-loaded="true"] {
    opacity: 0;
    filter: blur(24px);
    transition: opacity 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 9.75s, filter 2.5s ease 9.25s; }
    .p-splash[data-loaded="true"] .p-splash_tagline_animation {
      opacity: 0;
      transition: opacity 1s ease 5.5s; }
      .p-splash[data-loaded="true"] .p-splash_tagline_animation span {
        animation: splash-tiping-sp 1.8s ease 1s forwards; }
        @media screen and (min-width: 767px) {
          .p-splash[data-loaded="true"] .p-splash_tagline_animation span {
            animation: splash-tiping-pc 1.8s ease 1s forwards; } }
        .p-splash[data-loaded="true"] .p-splash_tagline_animation span::before {
          animation: splash-flash .5s ease infinite; }
      .p-splash[data-loaded="true"] .p-splash_tagline_animation:last-child span {
        animation-delay: 3s;
        animation-timing-function: cubic-bezier(0.43, 0.01, 0, 0.99); }
        .p-splash[data-loaded="true"] .p-splash_tagline_animation:last-child span::before {
          animation-delay: 2.5s; }
    .p-splash[data-loaded="true"] .p-splash_logo {
      opacity: 1;
      transition: opacity .1s ease 6.5s; }
      .p-splash[data-loaded="true"] .p-splash_logo_mark::after {
        transform: scale(1.8) rotate(135deg) translate(50%, 0%);
        transition: transform 1.25s ease 6.5s; }
      .p-splash[data-loaded="true"] .p-splash_logo_ja svg:first-child {
        fill: #707070;
        stroke: #585858;
        transition: fill 1s ease 7.45s, stroke-dashoffset 1s ease 7.2s; }
    .p-splash[data-loaded="true"] .p-splash_stroke {
      stroke-dashoffset: 15;
      transition: stroke-dashoffset 1s ease 7.1s; }
      .p-splash[data-loaded="true"] .p-splash_stroke.--long {
        stroke-dashoffset: -45; }
  .p-splash[aria-hidden="true"] {
    display: none; }

@keyframes splash-flash {
  0% {
    opacity: 0; }
  49% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes splash-tiping-sp {
  0% {
    transform: translateX(1.5625rem); }
  9% {
    transform: translateX(1.5625rem); }
  10% {
    transform: translateX(3.4375rem); }
  21% {
    transform: translateX(3.4375rem); }
  22% {
    transform: translateX(5.3125rem); }
  31% {
    transform: translateX(5.3125rem); }
  32% {
    transform: translateX(7.1875rem); }
  43% {
    transform: translateX(7.1875rem); }
  44% {
    transform: translateX(9.0625rem); }
  53% {
    transform: translateX(9.0625rem); }
  54% {
    transform: translateX(10.9375rem); }
  65% {
    transform: translateX(10.9375rem); }
  66% {
    transform: translateX(12.8125rem); }
  75% {
    transform: translateX(12.8125rem); }
  76% {
    transform: translateX(14.6875rem); }
  84% {
    transform: translateX(14.6875rem); }
  99% {
    opacity: 1; }
  100% {
    transform: translateX(14.6875rem);
    opacity: 0; } }

@keyframes splash-tiping-pc {
  0% {
    transform: translateX(3.125rem); }
  9% {
    transform: translateX(3.125rem); }
  10% {
    transform: translateX(6.875rem); }
  21% {
    transform: translateX(6.875rem); }
  22% {
    transform: translateX(10.625rem); }
  31% {
    transform: translateX(10.625rem); }
  32% {
    transform: translateX(14.375rem); }
  43% {
    transform: translateX(14.375rem); }
  44% {
    transform: translateX(18.125rem); }
  53% {
    transform: translateX(18.125rem); }
  54% {
    transform: translateX(21.875rem); }
  65% {
    transform: translateX(21.875rem); }
  66% {
    transform: translateX(25.625rem); }
  75% {
    transform: translateX(25.625rem); }
  76% {
    transform: translateX(29.375rem); }
  99% {
    opacity: 1; }
  100% {
    transform: translateX(29.375rem);
    opacity: 0; } }

.p-timeline {
  position: relative;
  width: 100%;
  overflow: visible; }
  @media screen and (min-width: 767px) {
    .p-timeline {
      display: flex;
      flex-direction: row-reverse;
      max-width: 66.75rem;
      margin: 5.9375rem auto 0 auto;
      transform: translateX(1.5rem); }
      .p-timeline + .p-timeline {
        margin-top: 5.0625rem; } }
  .p-timeline:last-child {
    margin-bottom: 11rem; }
    @media screen and (min-width: 767px) {
      .p-timeline:last-child {
        margin-bottom: 7rem; } }
  .p-timeline_headline {
    margin: 2.5rem 0 0.5rem 0;
    text-align: center;
    font-size: var(--text-headline--en);
    line-height: var(--line-headline--en);
    letter-spacing: var(--letter-headline--en); }
    @media screen and (min-width: 767px) {
      .p-timeline_headline {
        writing-mode: vertical-rl;
        display: flex;
        text-align: left;
        width: 2rem;
        margin: 0;
        font-size: var(--text-headline--sm);
        line-height: var(--line-headline--sm);
        letter-spacing: var(--letter-headline--sm); }
        .p-timeline_headline::after {
          flex-grow: 1;
          content: "";
          display: block;
          width: 1px;
          margin: 0.75rem 0.875rem 0 1.125rem;
          background-color: #e7e7e7; } }
  .p-timeline[data-top] .p-timeline_headline {
    margin-top: 3.75rem; }
    @media screen and (min-width: 767px) {
      .p-timeline[data-top] .p-timeline_headline {
        margin-top: 2.5rem; } }
  .p-timeline_card {
    width: calc(100% + 1.25rem);
    margin-left: -0.625rem;
    padding: 1.875rem 1.875rem 2.5rem 1.875rem; }
    @media screen and (min-width: 767px) {
      .p-timeline_card {
        width: calc(100% - var(--text-headline) * var(--line-headline) - 1rem);
        margin-left: 0;
        margin-right: 1rem;
        padding: 2.5rem; } }
    @media screen and (min-width: 1023px) {
      .p-timeline_card {
        padding: 4.5rem 5rem; } }
    .p-timeline_card p {
      margin-top: 0.9375rem; }
  .p-timeline_summary {
    font-size: var(--text-headline);
    line-height: var(--line-headline);
    letter-spacing: var(--letter-headline); }
    @media screen and (min-width: 767px) {
      .p-timeline_summary {
        font-size: var(--text-headline--sm);
        line-height: var(--line-headline--sm);
        letter-spacing: var(--letter-headline--sm); } }

.p-what-we-do {
  width: 100vw;
  margin-top: 7.5rem;
  margin-left: -1.25rem; }
  @media screen and (min-width: 767px) {
    .p-what-we-do {
      width: calc(100% + 10rem);
      margin-top: 10rem;
      margin-bottom: 5rem;
      margin-left: -5rem; } }
  @media screen and (min-width: 1387px) {
    .p-what-we-do {
      margin-bottom: 13.5625rem; } }
  @media print {
    .p-what-we-do {
      margin-top: 3.75rem; } }
  .p-what-we-do_title {
    padding-left: 1.25rem;
    z-index: 99; }
    @media screen and (min-width: 767px) {
      .p-what-we-do_title {
        padding-left: 5rem; } }
    @media screen and (min-width: 1023px) {
      .p-what-we-do_title {
        padding-left: 6.25rem; } }

@media print {
  @page {
    size: auto;
    margin-top: 0mm; }
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  body {
    -webkit-print-color-adjust: exact; }
  a[href^="#"]::after {
    content: ""; }
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.u-relative, .l-nav_button_hamburger, .c-button, .c-arrow, .c-accordion label, .p-about_message, .p-about_member_image picture, .p-business_contents_wrap, .p-form_input, .p-outline_content, .p-service, .p-service_number, .p-splash_tagline_animation, .p-splash_logo_mark, .p-splash_logo_ja, .p-what-we-do_title {
  position: relative; }

.u-absolute, .l-nav_contents, .c-button::before, .c-curtain, .c-curtain::before, .c-curtain::after, .c-accordion label::before, .c-accordion label::after, .c-title-animation.--confirm, .c-title-animation.--thank, .p-about_member_image img, .p-business_contents_wrap::after, .p-form_input::after, .p-hero.--business .p-hero_content, .p-service_number .c-title-animation, .p-splash_tagline_animation span, .p-splash_logo, .p-splash_logo_mark::after, .p-splash_logo_ja svg:last-child {
  position: absolute; }

.u-fixed, .l-nav, .l-nav::after, .p-splash {
  position: fixed; }

.u-block, .l-footer_contact_inner, .l-header_logo, .l-header_logo_mark, .l-header_logo_ja, .l-nav::after, .l-nav_button, .l-nav_button_hamburger::before,
.l-nav_button_hamburger span, .l-nav_button_hamburger::after, .c-button::before, .c-curtain::before, .c-curtain::after, .c-dl dt,
.c-dl dd, .c-accordion label, .c-accordion label::before, .c-accordion label::after, .p-about_member_image picture, .p-about_member_name span, .p-business, .p-business_header_tagline svg,
.p-business_header_tagline span, .p-business_contents_wrap::after, .p-contact_thank[data-sended="true"], .p-form_input::after, .p-hero.--business .p-hero_title svg,
.p-hero.--business .p-hero_title span, .p-service_number .c-title-animation, .p-service_name small, .p-splash_logo_mark::after {
  display: block; }

.u-inline-block, .l-nav_links a, .c-arrow::before, .p-splash_tagline_animation span::before, .p-splash_tagline_animation span::after {
  display: inline-block; }

.u-flex, .l-nav_button_hamburger, .p-splash, .p-splash_tagline_animation span, .p-splash_logo {
  display: flex; }

.u-w-full, .l-header_logo_mark, .l-header_logo_ja, .l-nav_button_inner, .l-nav_button_hamburger::before,
.l-nav_button_hamburger span, .l-nav_button_hamburger::after, .c-curtain::before, .c-curtain::after, .p-about_message, .p-about_member_image picture, .p-business_header_tagline, .p-form_input::after, .p-hero.--business .p-hero_content, .p-splash, .p-splash_tagline_animation span, .p-splash_logo, .p-splash_logo_mark::after, .u-transformer_fixed {
  width: 100%; }

@media screen and (min-width: 767px) {
  .u-max-w-12--auto {
    max-width: 76.75rem; } }

@media screen and (min-width: 767px) {
  .u-max-w-10--auto {
    max-width: 63.75rem; } }

@media screen and (min-width: 767px) {
  .u-max-w-8, .u-max-w-8--auto {
    max-width: 50.75rem; } }

.u-h-full, .l-nav_button_inner, .c-curtain::before, .c-curtain::after, .p-business_contents_wrap::after, .p-hero.--business .p-hero_content, .p-splash, .p-splash_tagline_animation span, .p-splash_logo, .p-splash_logo_mark::after, .u-transformer_fixed {
  height: 100%; }

.u-mh-auto, .u-max-w-12--auto, .u-max-w-10--auto, .u-max-w-8--auto {
  margin-left: auto;
  margin-right: auto; }

.u-title, .p-about_message_tagline, .p-policy_headline, .p-timeline_summary {
  font-family: var(--font-title); }

.u-title--en, .p-timeline_headline, .u-headline-sp-title-pc, .l-nav_link_title, .p-profile_headline {
  font-family: var(--font-title--en); }

.u-title, .p-about_message_tagline, .p-policy_headline, .p-timeline_summary, .u-title--en, .p-timeline_headline, .u-headline-sp-title-pc, .l-nav_link_title, .p-profile_headline {
  color: #585858;
  font-weight: var(--weight-title); }

.u-text-small-only-sp, .l-footer_contact_message, .c-accordion_menu li a, .p-business_contents_inner p, .p-form_input label, .p-form_input input::placeholder,
.p-form_input textarea::placeholder, .p-timeline_card p {
  font-size: var(--text-small);
  line-height: var(--line-small);
  letter-spacing: var(--letter-small); }
  @media screen and (min-width: 1023px) {
    .u-text-small-only-sp, .l-footer_contact_message, .c-accordion_menu li a, .p-business_contents_inner p, .p-form_input label, .p-form_input input::placeholder,
    .p-form_input textarea::placeholder, .p-timeline_card p {
      font-size: var(--text-default);
      line-height: var(--line-default);
      letter-spacing: var(--letter-default); } }

@media screen and (min-width: 1023px) {
  .u-text-small-only-pc, .p-policy p,
  .p-policy ul {
    font-size: var(--text-small);
    line-height: var(--line-small);
    letter-spacing: var(--letter-small); } }

.u-headline-sp-title-pc, .l-nav_link_title, .p-profile_headline {
  font-size: var(--text-headline--en);
  line-height: var(--line-headline--en);
  letter-spacing: var(--letter-headline--en); }
  @media screen and (min-width: 1023px) {
    .u-headline-sp-title-pc, .l-nav_link_title, .p-profile_headline {
      font-size: var(--text-title--en);
      line-height: var(--line-title--en);
      letter-spacing: var(--letter-title--en); } }

.u-color-white {
  color: #fff; }

.u-bg-white, .l-nav::after, .l-nav_contents, .p-splash, .p-splash_tagline_animation span::after, .p-timeline_card {
  background-color: #fff; }

.u-bg-image, .p-about_member_image picture, .p-hero_bg {
  background-color: #e7e7e7; }

.u-border-bottom, .c-dl dd:last-child, .p-form_input {
  border-bottom: 1px solid #cfcfcf; }

.u-shadow {
  box-shadow: 0.5rem 1.25rem 3.75rem 0 rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 767px) {
    .u-shadow {
      box-shadow: 0.625rem 5rem 3.75rem 0 rgba(0, 0, 0, 0.08); } }

.u-drop-shadow {
  filter: drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.8)); }

.u-drop-shadow--sm {
  filter: drop-shadow(0 0 1.25rem rgba(0, 0, 0, 0.8)); }

.u-transition, .l-nav_contents, .l-nav_links, .l-nav_link_title, .c-button, .c-button::before, .c-accordion label::before, .c-accordion label::after, .c-accordion_menu, .p-form_input, .p-form_input::after {
  transition: .3s ease; }

.u-content, .l-nav::after, .l-nav_button_hamburger::before, .l-nav_button_hamburger::after, .c-curtain::before, .c-curtain::after, .p-business_contents_wrap::after, .p-splash_tagline_animation span::before, .p-splash_tagline_animation span::after, .p-splash_logo_mark::after {
  content: ""; }

.u-transformer {
  transform-origin: center bottom;
  transform: scale(0.9, 0.96);
  overflow: hidden; }
  .u-transformer_fixed {
    transform-origin: center bottom;
    transform: scale(1.1111, 1.0417); }

[data-loaded="true"] .u-transformer {
  transform: scale(1);
  transition: transform .5s ease; }
  [data-loaded="true"] .u-transformer_fixed {
    transform: scale(1);
    transition: transform .5s ease; }

@media print {
  .u-transformer {
    transform: scale(1); }
    .u-transformer_fixed {
      transform: scale(1); } }

.u-fadein {
  opacity: 0; }

.u-fadein[data-loaded="true"] {
  opacity: 1;
  transition: opacity .4s ease; }

@media print {
  .u-fadein {
    opacity: 1; } }

@media print {
  .u-print-break {
    page-break-after: always; } }
