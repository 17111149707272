// ---------------------------------------------------------------------
//  Media Query
// ---------------------------------------------------------------------
$breakpoints: (
  "tablet": 768,
  "pc": 1024,
  "pc--lg": 1388,
);

@mixin mediaQueryFor($media: tablet) {
  @media screen and (min-width: #{map-get($breakpoints, $media) - 1}px) {
    @content;
  }
}

// ---------------------------------------------------------------------
//  px -> rem
// ---------------------------------------------------------------------

@function rem($px: 16) {
  @return $px / 16 * 1rem;
}

// ---------------------------------------------------------------------
//  Grid
// ---------------------------------------------------------------------
$w-column: rem(84);
$w-gutter: rem(20);
$w-sidebar: rem(80);

@function width($colNum: 12) {
  @return #{$colNum * $w-column + ($colNum - 1) * $w-gutter};
}

// ---------------------------------------------------------------------
//  Color
// ---------------------------------------------------------------------
$color-key-navy:  #114564;
$color-key-navy-light: #3f7095;
$color-key-navy-bg:  #f1f6f5;
$color-key-red:  #A72019;
$color-key-red-light:  #b60005;
$color-key-red-bg: #f6f1f1;

$color-black-100: #000;
$color-gray-100: #282828;
$color-gray-90: #404040;
$color-gray-80: #585858;
$color-gray-70: #707070;
$color-gray-60: #888;
$color-gray-50: #9f9f9f;
$color-gray-40: #b7b7b7;
$color-gray-30: #cfcfcf;
$color-gray-20: #e7e7e7;
$color-gray-00: #fff;

$color-title: $color-gray-80;
$color-title--transparent: rgba(88, 88, 88, 0);
$color-text: $color-gray-100;
$color-text--transparent: rgba(40, 40, 40, 0);
$color-border: $color-gray-30;
$color-bg: $color-gray-00;
$color-bg--image: $color-gray-20;
$color-bg--transparent: rgba(255, 255, 255, 0);

// ---------------------------------------------------------------------
//  Font Family
// ---------------------------------------------------------------------
$font-yu-gothic: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic";
$font-hiragino: "Hiragino Sans W3";
$font-optima: "Optima Nova";
$font-helvetica: "Helvetica Neue";
$font-midashigo: "Midashi Go MB31";

:root {
  // ---------------------------------------------------------------------
  //  Font Family
  // ---------------------------------------------------------------------
  --font-title: #{$font-midashigo, $font-yu-gothic}, sans-serif;
  --font-title--en: #{$font-optima, $font-yu-gothic}, sans-serif;
  --font-link: #{$font-helvetica, $font-yu-gothic}, sans-serif;

  // ---------------------------------------------------------------------
  //  Font Size
  // ---------------------------------------------------------------------
  --text-title--lg: #{rem(52)};
  --text-title: #{rem(25)};
  --text-title--en: #{rem(25)};
  --text-headline: #{rem(20)};
  --text-headline--en: #{rem(22)};
  --text-headline--sm: #{rem(20)};
  --text-default: 1rem;
  --text-small: #{rem(13)};

  @include mediaQueryFor(pc) {
    --text-title--lg: #{rem(90)};
    --text-title: #{rem(30)};
    --text-title--en: #{rem(32)};
    --text-headline: #{rem(22)};
    --text-headline--en: #{rem(24)};
    --text-headline--sm: #{rem(18)};
    --text-default: #{rem(18)};
    --text-small: #{rem(15)};
  }

  // ---------------------------------------------------------------------
  //  Font Weight
  // ---------------------------------------------------------------------
  --weight-default: normal;
  --weight-title: normal;

  @include mediaQueryFor(pc) {
    --weight-default: 500;
  }

  // ---------------------------------------------------------------------
  //  Line Height
  // ---------------------------------------------------------------------
  --line-title--lg: 1.5;
  --line-title: 1.5;
  --line-title--en: 1.5;
  --line-headline: 1.5;
  --line-headline--en: 1.5;
  --line-headline--sm: 1.5;
  --line-default: 2;
  --line-small: 2;

  @include mediaQueryFor(pc) {
    --line-title--lg: #{rem(112)};
    --line-title: 1.5;
    --line-title--en: 1.3125;
    --line-headline: 1.5;
    --line-headline--en: 1.38;
    --line-headline--sm: 1.5;
    --line-default: 2;
    --line-small: 2;
  }

  @media print {
    --line-default: 1.8;
  }

  // ---------------------------------------------------------------------
  //  Letter Spacing
  // ---------------------------------------------------------------------
  // em を基準にすると割り切れないため、rem（16px）を基準とする
  --letter-title--lg: #{rem(3.99)};
  --letter-title: #{rem(.96)};
  --letter-title--en: #{rem(.96)};
  --letter-headline: #{rem(.2)};
  --letter-headline--en: #{rem(.22)};
  --letter-headline--sm: #{rem(.2)};
  --letter-default: normal;
  --letter-small: normal;

  @include mediaQueryFor(pc) {
    --letter-title--lg: #{rem(7)};
    --letter-title: #{rem(.6)};
    --letter-title--en: #{rem(1.14)};
    --letter-headline: #{rem(.7)};
    --letter-headline--en: #{rem(.76)};
    --letter-headline--sm: #{rem(.57)};
    --letter-default: #{rem(.45)};
    --letter-small: #{rem(.38)};
  }

  // ---------------------------------------------------------------------
  //  Sizing
  // ---------------------------------------------------------------------
  --width-logo: #{rem(76)};
  --height-logo: #{rem(82)};

  @include mediaQueryFor(pc) {
    --width-logo: #{rem(84)};
    --height-logo: #{rem(91)};
  }
}
