.c-dl {
  width: calc(100% + #{rem(20)});
  margin-right: 0;
  margin-left: auto;

  @include mediaQueryFor(tablet) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: rem(1071);
  }

  @media print {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  dt,
  dd {
    @extend .u-block;
  }

  dt {
    @extend .c-text-small;
    width: 100%;
    padding-top: rem(11);
    font-weight: inherit;
    border-top: 1px solid $color-border;

    @include mediaQueryFor(tablet) {
      padding: rem(14) 0 rem(15) 0;

      &:nth-last-child(2) {
        border-bottom: 1px solid $color-border;
      }
    }

    @media print {
      width: rem(200);

      &:nth-last-child(2) {
        border-bottom: 1px solid $color-border;
      }
    }
  }

  dd {
    padding-bottom: rem(9);

    @include mediaQueryFor(tablet) {
      padding: rem(11) 0 rem(12) 0;
      border-top: 1px solid $color-border;
    }

    @media print {
      width: calc(100% - #{rem(200)});
      padding-top: rem(8);
      border-top: 1px solid $color-border;
    }

    &:last-child {
      @extend .u-border-bottom;
      padding-bottom: rem(20);

      @include mediaQueryFor(tablet) {
        padding-bottom: rem(22);
      }
    }
  }

  li {
    margin-left: rem(20);
    list-style: disc;
  }
}
