.p-policy {
  &_title {
    margin-top: rem(160);

    @include mediaQueryFor(tablet) {
      margin-top: rem(200);
    }
  }

  &_contents {
    margin: rem(75) auto rem(88) auto;

    @include mediaQueryFor(tablet) {
      margin: rem(94) auto rem(128) auto;
    }
  }

  &_headline {
    @extend .u-title;
    margin: rem(52) 0 rem(13) 0;
    font-size: var(--text-headline);
    letter-spacing: var(--letter-headline);
    line-height: var(--line-headline);

    @include mediaQueryFor(tablet) {
      margin: rem(34) 0 rem(13) 0;
      font-size: var(--text-headline--sm);
      letter-spacing: var(--letter-headline--sm);
      line-height: var(--line-headline--sm);
    }
  }

  & p,
  & ul {
    @extend .u-text-small-only-pc;
  }

  & ul {
    list-style: disc;
    margin: rem(12) 0 rem(12) rem(20);

    @include mediaQueryFor(tablet) {
      margin-top: rem(20);
    }
  }
}
