.l-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: rem(63) rem(64) rem(16) rem(64);
  transition: opacity 1.2s ease .2s;
  z-index: 998;

  @include mediaQueryFor(pc) {
    top: rem(56);
    padding: 0 #{$w-sidebar};
    background-color: transparent;
    box-shadow: none;
  }

  @include mediaQueryFor(pc--lg) {
    left: calc(50% - #{width(7)} - #{$w-gutter * .5});
    padding: 0 #{$w-column + $w-gutter};
  }

  @media print {
    padding: rem(40) rem(20);
  }

  &_logo {
    @extend .u-block;
    width: var(--width-logo);
    fill: $color-gray-70;

    &_mark {
      @extend .u-block;
      @extend .u-w-full;
      height: var(--height-logo);
    }

    &_ja {
      @extend .u-block;
      @extend .u-w-full;
      height: rem(11);
      margin-top: rem(11);

      @include mediaQueryFor(pc) {
        height: rem(12);
        transition: .5s ease;
      }
    }
  }

  &[data-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s ease, opacity .5s ease;

    @include mediaQueryFor(pc) {
      visibility: visible;
      opacity: 1;

      .l-header_logo_ja {
        visibility: hidden;
        opacity: 0;
      }
    }

    @media print {
      position: absolute;
      visibility: visible;
      opacity: 1;
    }
  }
}
