.l-footer {
  @extend .l-main;
  text-align: center;
  padding-top: rem(40);

  @include mediaQueryFor(tablet) {
    padding-top: rem(80);
  }

  &_contact {
    max-width: width(8);
    margin-bottom: rem(40);
    opacity: 0;
    transform: scale(.7);

    @include mediaQueryFor(tablet) {
      margin: 0 auto rem(114) auto;
    }

    @media print {
      margin: 0 auto;
    }

    &_inner {
      @extend .u-block;
      padding: rem(22) rem(20) rem(28) rem(20);
      line-height: 1;
      text-align: center;

      @include mediaQueryFor(tablet) {
        padding: rem(66) rem(32) rem(56) rem(32);
        cursor: pointer;
      }
    }

    &_title {
      @extend .c-headline--en;

      @include mediaQueryFor(tablet) {
        font-size: var(--text-title--en);
        line-height: var(--line-title--en);
        letter-spacing: var(--letter-title--en);
      }
    }

    &_message {
      @extend .u-text-small-only-sp;
      margin-top: rem(21);
      margin-bottom: rem(24);

      @include mediaQueryFor(tablet) {
        margin-top: rem(27);
        margin-bottom: rem(23);

        br {
          display: none;
        }
      }

      @media print {
        br {
          display: none;
        }
      }
    }

    &[data-loaded="true"] {
      opacity: 1;
      transform: scale(1);
      transition: .8s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  &_copyright {
    margin-top: rem(31);
    margin-bottom: rem(24);
    font-size: rem(14);
    font-family: var(--font-title--en);
    letter-spacing: rem(.35);
    line-height: 1;
  }

  &_cursor-stalker {
    display: none;

    @include mediaQueryFor(pc) {
      &[data-move="true"] {
        display: block;
        pointer-events: none;
        position: fixed;
        top: rem(-16);
        left: rem(-16);
        width: rem(32);
        height: rem(32);
        background: rgba(0, 0, 0, .1);
        border-radius: 50%;
        transform: translate(0, 0);
        transition: .5s, transform .2s;
        transition-timing-function: ease-out;
        z-index: 998;

        &[data-hover="true"] {
          top: rem(-64);
          left: rem(-64);
          width: rem(96);
          height: rem(96);

          &[data-focused="true"] {
            top: rem(0);
            left: rem(0);
            width: rem(0);
            height: rem(0);
          }
        }
      }
    }
  }
}
