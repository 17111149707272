.c-arrow {
  @extend .u-relative;
  display: inline-block;
  font-size: 0;
  letter-spacing: 0;
  line-height: 1;

  &::before {
    @extend .u-inline-block;
    content: "";
    width: rem(81);
    height: rem(15);
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2281%22%20height%3D%2215%22%20viewBox%3D%220%200%2081%2015%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M77.9412%2013.6722L65.1077%200.838713L65.798%200.148438L80.298%2014.6484H0V13.6722H77.9412Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E');
  }

  span {
    margin-left: rem(10);
    font-family: var(--font-link);
    font-size: rem(15);
    font-weight: normal;
    letter-spacing: var(--letter-small);
    line-height: var(--line-small);
  }

  &--reverse {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: baseline;

    &::before {
      transform: rotateY(180deg);
    }

    span {
      margin-right: rem(10);
    }
  }

  @media print {
    display: none;
  }
}
