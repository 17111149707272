.c-button {
  @extend .u-relative;
  @extend .u-transition;
  display: block;
  width: rem(260);
  height: rem(48);
  padding: rem(6) 0 rem(10) 0;
  text-align: center;
  border-radius: rem(24);

  &::before {
    @extend .u-absolute;
    @extend .u-block;
    @extend .u-transition;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: rem(24);
    box-shadow: rem(4) rem(8) rem(24) 0 rgba(0, 0, 0, .24);
  }

  &:hover {
    filter: contrast(.4);
  }

  &:active {
    filter: contrast(1);
    transform: translateY(#{rem(4)});

    &::before {
      box-shadow: rem(4) rem(4) rem(12) 0 rgba(0, 0, 0, .24);
    }
  }

  @media print {
    display: none;
  }
}
