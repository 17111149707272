.p-outline {
  @include mediaQueryFor(tablet) {
    padding-left: #{$w-column};
  }

  @media print {
    margin-top: rem(80);
  }

  &_title {
    text-align: right;

    @include mediaQueryFor(tablet) {
      margin-right: rem(32);
    }
  }

  &_content {
    @extend .u-relative;
    margin-top: rem(48);

    @include mediaQueryFor(tablet) {
      margin-top: rem(84);

      .c-dl {
        dt {
          width: rem(120);
        }

        dd {
          width: calc(100% - #{rem(120)});
          padding: rem(14) 0 rem(15) 0;
          font-size: var(--text-small);
          letter-spacing: var(--letter-small);
          line-height: var(--line-small);
        }
      }
    }

    .c-curtain {
      width: calc(100% + #{rem(20)});
      margin-right: 0;
      margin-left: auto;

      @include mediaQueryFor(tablet) {
        width: 100%;
      }
    }

    &[data-loaded="true"] {
      .c-curtain::before {
        transition: transform 1s ease;
      }
    }
  }
}
