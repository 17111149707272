.p-what-we-do {
  width: 100vw;
  margin-top: rem(120);
  margin-left: rem(-20);

  @include mediaQueryFor(tablet) {
    width: calc(100% + #{rem(160)});
    margin-top: rem(160);
    margin-bottom: rem(80);
    margin-left: rem(-80);
  }

  @include mediaQueryFor(pc--lg) {
    margin-bottom: rem(217);
  }

  @media print {
    margin-top: rem(60);
  }

  &_title {
    @extend .u-relative;
    padding-left: rem(20);
    z-index: 99;

    @include mediaQueryFor(tablet) {
      padding-left: rem(80);
    }

    @include mediaQueryFor(pc) {
      padding-left: rem(100);
    }
  }
}
