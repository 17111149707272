.p-about {
  padding-top: rem(85);
  padding-bottom: rem(72);

  @include mediaQueryFor(tablet) {
    padding-top: rem(196);
    padding-bottom: rem(200);
  }

  &_title {
    padding: rem(12) 0 rem(10) 0;
    text-align: right;

    @include mediaQueryFor(pc) {
      padding: rem(29) 0 rem(10) 0;
    }
  }

  &_message {
    @extend .u-relative;
    @extend .u-w-full;
    margin-top: rem(115);

    @include mediaQueryFor(tablet) {
      margin-top: rem(36);
    }

    &_tagline {
      @extend .u-title;
      margin-bottom: rem(26);
      font-size: var(--text-headline);
      letter-spacing: var(--letter-headline);
      line-height: 2;

      @include mediaQueryFor(tablet) {
        margin-bottom: rem(48);
      }

      @include mediaQueryFor(pc) {
        margin-bottom: rem(70);
        font-size: var(--text-title);
        letter-spacing: var(--letter-title);
        line-height: var(--line-title);
      }
    }

    p + p {
      margin-top: calc(var(--line-default) * var(--text-default));
    }
  }

  &_member {
    display: block;
    margin-top: rem(68);

    @include mediaQueryFor(tablet) {
      display: flex;
      margin-top: rem(160);
    }

    @media print {
      display: flex;
      margin-top: rem(80);
    }

    &_image {
      width: rem(280);
      margin: 0 auto;
      z-index: -1;

      @include mediaQueryFor(tablet) {
        width: 38.83%;
        min-width: rem(280);
        max-width: rem(396);
        margin: 0 #{$w-gutter} 0 0;
      }

      @media print {
        width: 38.83%;
        min-width: rem(280);
        max-width: rem(396);
        margin: 0 #{$w-gutter} 0 0;
      }

      picture {
        @extend .u-relative;
        @extend .u-block;
        @extend .u-bg-image;
        @extend .u-w-full;
        padding-bottom: 100%;
      }

      img {
        @extend .u-absolute;
      }
    }

    &_contents {
      margin: rem(20) rem(8) 0 rem(8);

      @include mediaQueryFor(tablet) {
        flex-grow: 1;
        max-width: width(6);
        margin: rem(20) 0 0 0;
        transform: translateX(#{-1.75 - $w-gutter});
      }

      @media print {
        flex-grow: 1;
        max-width: width(6);
        margin: rem(20) 0 0 0;
        transform: translateX(#{-1.75 - $w-gutter});
      }

      p {
        margin: rem(21) 0 rem(29) 0;

        @include mediaQueryFor(tablet) {
          margin: rem(10) 0 rem(27) 0;
        }

        @media print {
          margin: rem(10) 0 rem(27) 0;
        }
      }
    }

    &_name {
      span {
        @extend .u-block;

        &:first-child {
          margin-bottom: rem(4);

          @include mediaQueryFor(tablet) {
            margin-bottom: 0;
          }
        }
      }
    }

    &[data-right] {
      margin-top: rem(72);

      @include mediaQueryFor(tablet) {
        margin-top: rem(160);
        margin-right: 0;
        transform: translateX(#{1.75 + $w-gutter});
      }

      @media print {
        margin-top: rem(120);
        margin-right: 0;
        transform: translateX(#{1.75 + $w-gutter});
      }
    }
  }

  &[data-loaded="true"] {
    & .p-about_message {
      .c-curtain::before {
        transition: transform 1.5s ease;
      }
    }
  }
}
