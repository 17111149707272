.p-form {
  margin: rem(26) auto 0 auto;

  @include mediaQueryFor(tablet) {
    margin: rem(100) auto rem(40) auto;
  }

  &_input {
    @extend .u-relative;
    @extend .u-border-bottom;
    @extend .u-transition;
    margin: 0 rem(-20) 0 rem(20);

    @include mediaQueryFor(tablet) {
      margin-right: auto;
      margin-left: auto;
    }

    &::after {
      @extend .u-absolute;
      @extend .u-block;
      @extend .u-w-full;
      @extend .u-transition;
      content: "";
      bottom: -1px;
      left: 0;
      height: 2px;
      background-color: $color-border;
      transform-origin: left bottom;
      transform: scale(.6, 0);
    }

    label {
      @extend .u-text-small-only-sp;
      padding-top: rem(11);
      vertical-align: top;

      @include mediaQueryFor(tablet) {
        width: rem(110);
      }

      @include mediaQueryFor(pc) {
        width: rem(192);
      }
    }

    input,
    textarea {
      &::placeholder {
        @extend .u-text-small-only-sp;
        color: $color-gray-50;
      }

      &:focus {
        box-shadow: none;
      }
    }

    p {
      display: none;
    }

    input,
    textarea,
    p {
      width: 100%;
      padding-bottom: rem(9);
      color: inherit;
      outline: none;

      @include mediaQueryFor(tablet) {
        width: calc(100% - #{rem(116)});
      }

      @include mediaQueryFor(pc) {
        width: calc(100% - #{rem(198)});
      }
    }

    label,
    input,
    textarea {
      display: block;

      @include mediaQueryFor(tablet) {
        display: inline-block;
      }
    }

    label,
    input,
    textarea,
    p {
      @include mediaQueryFor(tablet) {
        padding: rem(57) 0 rem(6) 0;
      }
    }

    &[aria-invalid="true"] {
      color: $color-key-red-light;

      &::after {
        background-color: $color-key-red-light;
      }
    }

    &:focus-within {
      color: $color-title;

      &::after {
        background-color: $color-title;
      }
    }

    &[aria-invalid="true"],
    &:focus-within {
      &::after {
        transform: scale(1);
      }
    }

    &[aria-readonly="true"] {
      margin: 0;
      border-bottom: none;

      @include mediaQueryFor(tablet) {
        margin-right: auto;
        margin-left: auto;
      }

      &:focus-within::after {
        transform: scale(0);
      }

      label {
        @include mediaQueryFor(tablet) {
          padding-bottom: 0;
        }
      }

      p {
        display: inline-block;
        word-break: break-all;
        word-wrap: break-word;
      }

      input,
      textarea {
        display: none;
      }

      input,
      textarea,
      p,
      label {
        @include mediaQueryFor(tablet) {
          padding-top: rem(22);
        }
      }

      input,
      textarea,
      p {
        padding-bottom: 0;
      }
    }
  }

  &_footer {
    margin: rem(40) auto 0 auto;
    text-align: center;

    @include mediaQueryFor(tablet) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: center;
      margin: rem(80) auto 0 auto;
    }
  }

  &_button {
    margin: 0 auto rem(48) auto;
    padding-bottom: rem(10);
    font-size: 1rem;

    @include mediaQueryFor(tablet) {
      margin: 0 calc(8% + #{rem(136)}) 0 8%;
    }

    @include mediaQueryFor(pc) {
      margin: 0 calc(18.82% + #{rem(136)}) 0 18.82%;
    }

    &:disabled {
      pointer-events: none;
      opacity: .3;
    }
  }

  &_back {
    cursor: pointer;

    &[aria-hidden="true"] {
      display: none;
    }
  }

  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-readonly="true"] {
    margin-top: rem(44);

    @include mediaQueryFor(tablet) {
      margin-top: rem(133);
    }
  }
}
