.c-title,
.c-headline {
  color: $color-title;
  font-family: var(--font-title);
  font-weight: var(--weight-title);

  &--en,
  &--lg {
    font-family: var(--font-title--en);
    font-weight: var(--weight-title);
  }
}

.c-title {
  font-size: var(--text-title);
  letter-spacing: var(--letter-title);
  line-height: var(--line-title);

  &--lg {
    display: inline-block;
    font-size: var(--text-title--lg);
    letter-spacing: var(--letter-title--lg);
    line-height: var(--line-title--lg);
    text-align: left;
  }
}

.c-headline {
  font-size: var(--text-headline);
  letter-spacing: var(--letter-headline);
  line-height: var(--line-headline);

  &--en {
    font-size: var(--text-headline--en);
    letter-spacing: var(--letter-headline--en);
    line-height: var(--line-headline--en);
  }
}

.c-headline-sp-title-pc {
  @extend .c-title--en;
  font-size: var(--text-headline--en);
  line-height: var(--line-headline--en);
  letter-spacing: var(--letter-headline--en);

  @include mediaQueryFor(pc) {
    font-size: var(--text-title--en);
    line-height: var(--line-title--en);
    letter-spacing: var(--letter-title--en);
  }
}
